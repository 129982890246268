import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import httpClient from "../../_util//api";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import AddEvent from "../../pages/Franchise/calendar/addEvent";
import { Tooltip } from 'primereact/tooltip';
import PrivateSlotReserveDialog from "../../pages/Franchise/calendar/private-slot-reserve-dialog";
import SlotReserveDialog from "../../pages/Franchise/calendar/slot-reserve-dialog";
import WaitListConfirmation from "../../shared/SuccessPopup";
import { hideLoader, setBadgeValue, setEventSuccess, showLoader } from "../../redux/mainSlice";
import VivToast from "../../shared/VivitechToast";
import SlotListDialog from "../../pages/Franchise/calendar/slot-list-dialog";
import ReservationCancelDialog from "../../pages/Franchise/calendar/reservation-cancel-dialog";
import EventListDialog from "../../pages/Franchise/calendar/events-list-dialog";
import EventViewDialog from "../../pages/Franchise/calendar/event-view-dialog";

const DayCalendar = forwardRef(({ calendarDate, filteredEventData, sendDataToParent, path }, ref) => {

    const [dayDate, setDayDate] = useState({});
    const [currentDate, setCurrentDate] = useState(`${new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()}-${new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}-${new Date()?.getFullYear()}`);
    const dispatch = useDispatch();
    const eventChange = useSelector((state) => state.mainSlice.eventSuccess);
    const locationId = useSelector((state) => state.mainSlice.locationId);
    const [createDate, setCreateDate] = useState()
    const WaitListConfirmationRef = useRef(null);
    const [hour, setHour] = useState([])
    const [eventListShow, setEventListShow] = useState(false)
    const [eventViewShow, setEventViewShow] = useState(false)
    const toast_Ref = useRef(null);
    const [weekDays, setWeekDays] = useState(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']);
    const [addEvent, setAddEvent] = useState(false)
    const [eventPopupData, setEventPopupData] = useState([])
    const [slotListShow, setSlotListShow] = useState(false)
    const [cancelSlotReserveShow, setCancelSlotReserveShow] = useState(false)
    const [slotReserveShow, setSlotReserveShow] = useState(false)
    const [privateSlotReserveShow, setPrivateSlotReserveShow] = useState(false)
    const [weekHours, setWeekHours] = useState([
        { key: '1:00 AM', label: '1 A.M' },
        { key: '2:00 AM', label: '2 A.M' },
        { key: '3:00 AM', label: '3 A.M' },
        { key: '4:00 AM', label: '4 A.M' },
        { key: '5:00 AM', label: '5 A.M' },
        { key: '6:00 AM', label: '6 A.M' },
        { key: '7:00 AM', label: '7 A.M' },
        { key: '8:00 AM', label: '8 A.M' },
        { key: '9:00 AM', label: '9 A.M' },
        { key: '10:00 AM', label: '10 A.M' },
        { key: '11:00 AM', label: '11 A.M' },
        { key: '12:00 PM', label: '12 P.M' },
        { key: '1:00 PM', label: '1 P.M' },
        { key: '2:00 PM', label: '2 P.M' },
        { key: '3:00 PM', label: '3 P.M' },
        { key: '4:00 PM', label: '4 P.M' },
        { key: '5:00 PM', label: '5 P.M' },
        { key: '6:00 PM', label: '6 P.M' },
        { key: '7:00 PM', label: '7 P.M' },
        { key: '8:00 PM', label: '8 P.M' },
        { key: '9:00 PM', label: '9 P.M' },
        { key: '10:00 PM', label: '10 P.M' },
        { key: '11:00 PM', label: '11 P.M' },
        { key: '12:00 AM', label: '12 A.M' }
    ]);

    const [events, setEvents] = useState([]);

    const sendData = (length) => {
        sendDataToParent && sendDataToParent(length);
    };


    const getEventSlots = async (from_date) => {
        dispatch(showLoader())
        try {
            const resp = await httpClient.post(`${path == "my-reservation" ? '/my-reservations' : '/event-slots'}`, {
                event_type_id: filteredEventData,
                location_id: locationId,
                frequency: "week",
                from_date: from_date,
                to_date: from_date
            });
            setEvents(resp.data.data)
            sendData(8)
            getNotificationsCount()

        } catch (error) {
            console.log(error);
            
              toast_Ref.current?.showMessage(
                  "error",
                  error?.response?.data?.message || "An error occurred",
                  "",
                  "ic-error-notify"
                );
        }
        dispatch(hideLoader())
    }
    
    const getNotificationsCount = async () => {
        let apiResp = await httpClient.get("whomi")

        if (apiResp?.status == 200) {
            dispatch(setBadgeValue(apiResp.data.data.user.unread ? apiResp.data.data.user.unread : 0))
        }
    }

    useEffect(() => {
        setDate(calendarDate)
    }, [eventChange, calendarDate, filteredEventData, locationId]);

    const formatDate = (date) => {
        let day = String(date.getDate()).padStart(2, '0');
        let month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month
        let year = date.getFullYear();

        return `${year}-${month}-${day}`;
    };


    const handleShowMoreEvents = (data, event) => {
        event.stopPropagation()
        setEventPopupData(data)
        if (path == "location-schedule") {
            setSlotListShow(true)
        } else {
            setEventListShow(true)
        }

    }

    const handleEventShow = (data, event) => {
        event.stopPropagation()
        setEventPopupData(data)
        console.log(data);

        if (path == 'location-schedule' || path == 'my-reservation') {
            if (data && data.event_type_separate == 'scheduled_play') {
                if (data?.fully_booked) {
                    WaitListConfirmationRef.current.showPopUp()
                } else {
                    if (path == 'location-schedule') {
                    setSlotReserveShow(true)
                    } else {
                    setCancelSlotReserveShow(true)
                }
            }
            } else {
                setPrivateSlotReserveShow(true)
            }
        } else {
            setEventViewShow(true)
        }
    }

    function formatTimeRange(startTime, endTime) {
        const formatTime = (time) => {
            // Convert time string (e.g., "02:00 AM") to a Date object
            const [hour, minutePart] = time.split(':');
            const [minute, period] = minutePart.split(' ');

            // Convert hour to a number and remove leading zeros
            let hourNum = parseInt(hour, 10);

            // Format hour without leading zero for 12-hour format
            return `${hourNum} ${period}`;
        };

        // Format both start time and end time
        const formattedStartTime = formatTime(startTime);
        const formattedEndTime = formatTime(endTime);

        return `${formattedStartTime} - ${formattedEndTime}`;
    }

    const onConfirmWaitList = async () => {
        dispatch(showLoader())
        console.log(eventPopupData);

        try {
            const resp = await httpClient.post("/wait-list", {
                event_id: eventPopupData.event_id,
                occurrence_date: eventPopupData.occurrence_date,
                start_time: eventPopupData.start_time,
                end_time: eventPopupData.end_time
            });

            if (resp.status == 200) {
                toast_Ref.current?.showMessage(
                    "success",
                    "Waitlist Joined",
                    "",
                    "ic-square-check"
                )
                dispatch(setEventSuccess(`id-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`))
                setTimeout(() => {
                    WaitListConfirmationRef.current.hidePopUp()
                }, 500)
            }

        } catch (error) {
            console.log(error);
            toast_Ref.current?.showMessage(
                "error",
                error?.response?.data?.message || "An error occurred",
                "",
                "ic-error-notify"
            );
        }
        dispatch(hideLoader())
    }

    const setDate = async (date) => {
        let selectedDate = new Date(date);

        let date_obj = {
            date: selectedDate.getDate(),
            month: selectedDate.getMonth() + 1,
            year: selectedDate.getFullYear(),
            dayIndex: selectedDate.getDay()
        };

        setDayDate(date_obj);
        let formattedDate = formatDate(selectedDate);
        getEventSlots(formattedDate, formattedDate);
    };

    const renderEventsForDay = (date, hour) => {

        const dayEvents = events?.find(event => event.date === date);
        if (!dayEvents) return null;

        const slotEvents = dayEvents?.slots?.find(slot => slot.time === hour.key);

        if (!slotEvents) return null;

        return (<>
            {slotEvents.events.slice(0, 27).map((e, index) => (
                <div className={`event-name-${e.event_id}-${e.occurrence_date} mx-2 event-card  ${e.fully_booked && (path === 'location-schedule' || path === 'my-reservation') ? 'danger-bg' : (e.event_type_separate === "scheduled_play" && (path === 'location-schedule' || path === 'my-reservation')) ? 'schedule-play-bg' : 'primary-bg'} p-1 ${slotEvents?.events?.length > 2 ? '' : 'week-view-slot'}`} key={index} onClick={(ev) => handleEventShow(e, ev)
                } style={{ left: `${index * 40}px`, minWidth: index == (slotEvents?.events?.length - 1) && '-webkit-fill-available' }}>
                    <Tooltip target={`.event-name-${e.event_id}-${e.occurrence_date}`} content={e.event_type_name} position="top" showDelay={100} hideDelay={100} />
                    <p className="title">{e.event_type_name}</p>
                    <div className="flex align-items-center justify-content-between">
                        <div className="event-info">
                            <p>{formatTimeRange(e.start_time, e.end_time)}</p>
                            <p>{e.token_cost} <img src="/images/coin.svg" alt="coin" /></p>
                        </div>
                        <div>
                            {e.count && <div className="court-count-wraper">
                                <p className="pb-1 pl-2 pr-2 pt-1">{e.count}</p>
                            </div>}
                        </div>
                    </div>
                </div>
            ))}
            {slotEvents.events.length > 27 && (
                <div className={`event-card primary-bg p-1 lf-3 more-events view-more-events`} onClick={(ev) => handleShowMoreEvents(slotEvents.events, ev)}>
                    <p className="title-more">{slotEvents.events.length - 27}+</p>
                </div>
            )}
        </>
        );
    };



    return (<>
        <VivToast ref={toast_Ref} />
        <EventListDialog visible={eventListShow} onChange={() => setEventListShow(false)} data={eventPopupData} />
        <EventViewDialog visible={eventViewShow} onChange={() => setEventViewShow(false)} data={eventPopupData} />
        <SlotReserveDialog visible={slotReserveShow} onChange={() => setSlotReserveShow(false)} data={eventPopupData} />
        <SlotListDialog visible={slotListShow} onChange={() => setSlotListShow(false)} data={eventPopupData} />
        <PrivateSlotReserveDialog visible={privateSlotReserveShow} onChange={() => setPrivateSlotReserveShow(false)} data={eventPopupData} />
        <ReservationCancelDialog visible={cancelSlotReserveShow} onChange={() => setCancelSlotReserveShow(false)} data={eventPopupData} />
        <AddEvent visible={addEvent} onChange={() => setAddEvent(false)} id={null} time={hour} createDate={createDate} />
        <WaitListConfirmation
            ref={WaitListConfirmationRef}
            closable={true}
            title=""
            messageTitle={"All Courts are booked. We have a waiting list of 12 member. Do you want to join waitlist?"}
            onConfirm={onConfirmWaitList}
            onCancel={() => WaitListConfirmationRef.current.hidePopUp()}
            onHide={() => WaitListConfirmationRef.current.hidePopUp()} />

        <div className="day-calendar pl-7">
            <div className="grid m-0 header-row">
                        <div className="col calendar-header">
                        <p className={`${String(dayDate.date).padStart(2, '0')}-${String(dayDate.month).padStart(2, '0')}-${dayDate.year}` === currentDate ? 'p-date-active' : 'p-date'}>
                            {dayDate.date}
                            </p>
                        <p className="p-day">{weekDays[dayDate.dayIndex]}</p>
                        </div>
            </div>


            {weekHours.map((hour, ind) => (
                <div className="grid m-0 calendar-row" key={ind}>
                    <span className="calendar-row-cell-time">{hour.label}</span>
                            <div className="col calendar-row-cell">
                                {renderEventsForDay(
                                    `${dayDate.date < 10 ? '0' + dayDate.date : dayDate.date}-${dayDate.month >= 10 ? dayDate.month : `0${dayDate.month}`}-${dayDate.year}`,
                                    hour
                                )}
                            </div>
                </div>
            ))}



        </div>
    </>);
})

export default DayCalendar;