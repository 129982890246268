import React, { useEffect, useState } from "react";
import { Route, useNavigate, useLocation } from "react-router-dom";
import httpClient from "./api";
import { useDispatch, useSelector } from "react-redux";
import { setBadgeValue, setIsLegacyMember, setLocation_id, setRole, setUserDetail, setUserMenus, setUserRole } from "../redux/mainSlice";
import { hideMobileBar } from "../redux/sidebarSlice";

const ProtectedRoute = (props) => {
  const userMenus = useSelector((state) => state.mainSlice.userMenus);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  //const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(Boolean(localStorage.getItem("user-token")));

  const checkUserToken = async () => {
    const userToken = localStorage.getItem("user-token");
    if (!userToken || userToken === "undefined") {
      setIsLoggedIn(false);
      return navigate("/login");
    }
    
    let apiResp = await httpClient.get("whomi").catch((error) => { localStorage.removeItem("user-token"); });

    if (apiResp?.status == 200) {
      dispatch(setUserMenus(apiResp.data.data.menus));
      dispatch(setUserRole(apiResp.data.data.role));
      dispatch(setUserDetail(apiResp.data.data.user));
      dispatch(setIsLegacyMember(apiResp.data.data.user.is_legacy_member ? apiResp.data.data.user.is_legacy_member : false))
      dispatch(setRole(apiResp.data.data.user.role_name ? apiResp.data.data.user.role_name : null))
      dispatch(setBadgeValue(apiResp.data.data.user.unread ? apiResp.data.data.user.unread : 0))
      if (apiResp?.data?.data?.user?.location_id) {
        dispatch(setLocation_id(apiResp?.data?.data?.user?.location_id ? apiResp?.data?.data?.user?.location_id : null));
      }
    }

    setIsLoggedIn(true);
  };

  useEffect(() => { 
    dispatch(hideMobileBar());
    if(props?.allowRender == false){
      navigate('/AccessNotAllowed');
    }
  });
  
  useEffect(() => {
     checkUserToken();
  }, [isLoggedIn]);

  return <React.Fragment>{isLoggedIn ? props.children : null}</React.Fragment>;
};

export default ProtectedRoute;
