import React, { useState, useReducer, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import httpClient from "../../../../../_util/api";
import { RadioButton } from 'primereact/radiobutton';
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, setEventSuccess, showLoader } from "../../../../../redux/mainSlice";
import "../style.scss"
import { Checkbox } from "primereact/checkbox";
import { Tooltip } from 'primereact/tooltip';
import { Panel } from "primereact/panel";
import { Avatar } from 'primereact/avatar';
import { AutoComplete } from "primereact/autocomplete";
import ConfirmationPopup from "../../../../../shared/ConfirmationPopup";
import VivToast from "../../../../../shared/VivitechToast";
import { useLocation } from "react-router-dom";
import { Chip } from "primereact/chip";

const initialState = {
    event_id: null,
    event_type_id: null,
    slot_id: [],
    location_id: null,
    token_per_reservation: null,
    token_cost: null,
    use_my_token: true,
    invite_they_pay: [],
    invite_i_pay: [],
};

export default function PrivateSlotReserveComponent({ onEdit,onChange, data, viewMore, onDialogClose }) {
    const [formData, setFormData] = useState({});
    const toast_Ref = useRef(null);
    const location = useLocation();
    const dispatch = useDispatch();
    const locationId = useSelector((state) => state.mainSlice.locationId);
    const isLegacyMember = useSelector((state) => state.mainSlice.isLegacyMember);
    const [courts, setCourts] = useState([])
    const [fullyBooked, setFullyBooked] = useState(false)
    const [courtStates, setCourtStates] = useState(courts);
    const [isTheyPayCollapse, setIsTheyPayCollapse] = useState(true)
    const [isIPayCollapse, setIsIPayCollapse] = useState(true)
    const [useToken, setUseToken] = useState(true);
    const [edit, setEdit] = useState(false)
    const [editConfirmDialog, setEditConfirmDialog] = useState(false)
    const [eventName, setEventName] = useState("")
    const [maxEmails, setMaxEmails] = useState(0)
    const [checkedCount, setCheckedCount] = useState(0);
    const [inviteTheyPayEmails, setInviteTheyPayEmails] = useState([])
    const [inviteIPayEmails, setInviteIPayEmails] = useState([])
    const confirmationPopupRef = useRef(null);
    const tokenPurchasePopupRef = useRef(null);
    const [filteredEmails, setFilteredEmails] = useState([]);
    const [selectedTheyPayEmail, setSelectedTheyPayEmail] = useState([]);
    const [selectedIPayEmail, setSelectedIPayEmail] = useState([]);
    const [showError, setShowError] = useState("")
    const avatarColors = ['#A7E7F0', '#B1F0A7', '#FFE7A9'];

    const handleChange = ({ name, value }) => {
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if (edit) {      
            
            try {
                dispatch(showLoader())
                const response = await httpClient.post(`cancel-reservation-private`, {
                    event_id: data?.event_id,
                    event_type_id: data.event_type_id,
                    event_slot_id: data?.courts?.map(item => item?.id)
                });
                if (response?.status === 200) {       

                   toast_Ref.current?.showMessage(
                        "success",
                        response.data.message,
                        "",
                        "ic-square-check"
                    )
                    dispatch(setEventSuccess(`id-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`))
                    setTimeout(() => {
                        onChange()
                        if (onCloseDialog) {
                            onCloseDialog()
                        }
                    }, 1000)
                }

            } catch (error) {
                //console.log(error?.response?.data);

                if (error?.response?.data?.errors?.modal) {
                    tokenPurchasePopupRef.current.showPopUp();
                } else {
                    toast_Ref.current?.showMessage(
                        "error",
                        error?.response?.data?.message || "An error occurred",
                        "",
                        "ic-error-notify"
                    );
                }
            }
            dispatch(hideLoader())
        } else {

            try {
                dispatch(showLoader())
                const response = await httpClient.post("/make-court-reservation", formData);
                if (response?.status === 200) {

                    toast_Ref.current?.showMessage(
                        "success",
                        "Court Reserved Successfully",
                        "",
                        "ic-square-check"
                    )
                    dispatch(setEventSuccess(`id-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`))
                    setTimeout(() => {
                        onChange()
                        if (onCloseDialog) {
                            onCloseDialog()
                        }
                    }, 1000)
                }

            } catch (error) {
                //console.log(error?.response?.data);
                
                if (error?.response?.data?.errors?.modal) {
                    tokenPurchasePopupRef.current.showPopUp();
                } else {
                    toast_Ref.current?.showMessage(
                        "error",
                        error?.response?.data?.message || "An error occurred",
                        "",
                        "ic-error-notify"
                    );
                }
            }

            dispatch(hideLoader())
        }

    };

    const getUsers = async (search) => {
        try {
            const { data } = await httpClient.get(`/search-users/${search}`);
            console.log(data);
            
            if (data.length > 0) {
                setFilteredEmails(data)
            } else {
                setFilteredEmails([])

            }
        } catch (error) {
            toast_Ref.current?.showMessage(
                "error",
                error?.response?.data?.message || "An error occurred",
                "",
                "ic-error-notify"
            );
        }
    };


    const onCancel = async (e) => {
        e.preventDefault()
        setCourts([])
        // setFullyBooked(false)
        onChange();
    }

    const headerContent = () => (
        <div className="flex justify-content-between event-view-dialog-header">
            <h1>{data?.event_type_name}</h1>
            {edit && <div className="flex gap-2">
                <i className="ic-edit header-icon ml-2" onClick={() => onEditEvent(eventDetails)} />
                <i className="ic-delete header-icon ml-2" onClick={() => onDeleteEvent(eventDetails)} />
            </div>}
        </div>
    )

    function formatDateString(dateString) {
        // Split the string into parts
        const [dayOfWeek, monthAndDay] = dateString.split(', ');

        // Shorten the day of the week and the month
        const shortDayOfWeek = dayOfWeek.slice(0, 3); // "Wed"
        const [month, day] = monthAndDay.split(' ');
        const shortMonth = month.slice(0, 3); // "Sep"

        // Combine into the desired format
        return `${shortDayOfWeek}, ${shortMonth} ${day}`;
    }


    function formatTimeRange(startTime, endTime) {
        const formatTime = (time) => {
            // Convert time string (e.g., "02:00 AM") to a Date object
            const [hour, minutePart] = time.split(':');
            const [minute, period] = minutePart.split(' ');

            // Convert hour to a number and remove leading zeros
            let hourNum = parseInt(hour, 10);

            // Format hour without leading zero for 12-hour format
            return `${hourNum} ${period}`;
        };

        // Format both start time and end time
        const formattedStartTime = formatTime(startTime);
        const formattedEndTime = formatTime(endTime);

        return `${formattedStartTime} - ${formattedEndTime}`;
    }


    const footerContent = (<>
        <div className="flex gap-3 justify-content-end private-reserve-dialog-footer ">
            {showError && (<p className="error-message-add-event"><i className="ic-exclamation-circle"></i> You cxan not edit the reservation slot <br /> &nbsp;&nbsp;&nbsp;&nbsp; as court 1 has been booked.</p>)}
            {!viewMore && <Button
                onClick={onCancel}
                className="w-full btn-secondary text-white"
                label="Close"
                disabled={false}
            />}
            <Button
                className="w-full btn-primary text-white"
                label={
                    isLegacyMember && !formData.use_my_token && !edit
                        ? "Reserve Free"
                        : edit
                            ? "Cancel Reservation"
                            : "Reserve"
                }
                onClick={onSubmit}
                disabled={formData?.slot_id?.length < 1 || formData?.invite_they_pay?.length < maxEmails}
            />
        </div>
    </>
    );

    useEffect(() => {
        onLoad()
    }, []);





    const onLoad = async () => {
        
        if (isLegacyMember) {
            setUseMyToken(false)
            handleChange({ name: "use_my_token", value: false })
        }
        
        if (location.pathname.includes("my-reservation")) {
            if (onEdit) {
                onEdit(true);
            }
            setEdit(true)
            console.log("my-reservation");
            setCourtStates(data?.courts)
            setFullyBooked(data.fully_booked)

            const initialSelectedCourts = data?.courts
                .filter((court) => court.slots_reserved === 4)
                .map((court) => court.id);

            setCourts(initialSelectedCourts);
            setCheckedCount(initialSelectedCourts.length)

            
            const theyPayEmails = data.they_pay?.map(item => ({
                initial_buddy: true,
                buddy: true,
                email: item?.contact_value,
                isPaid: item?.status === "accepted" ? "paid" : item?.status,
                full_name: item?.contact_value,
            }))
            
            const ipayemails = data?.i_pay?.map(item => ({
                initial_buddy: true,
                buddy: true,
                email: item?.contact_value,
                isPaid: item?.status === "accepted" ? "paid" : item?.status,
                full_name: item.contact_value
            }))
            
            setInviteTheyPayEmails(theyPayEmails);
            
            setInviteIPayEmails(ipayemails);
            
            handleChange({ name: "invite_they_pay", value: theyPayEmails });
            handleChange({ name: "invite_i_pay", value: ipayemails });
            
            setIsTheyPayCollapse(false)
            setIsIPayCollapse(false)
            setMaxEmails(theyPayEmails?.length)

            // Optionally update maxEmails based on predefined data
            
        } else {
            
            setFormData({
                event_id: data?.event_id,
                event_type_id: data?.event_type_id,
                location_id: locationId,
                use_my_token: isLegacyMember ? false : true,
                token_per_reservation: data?.token_per_reservation,
                token_cost: data?.token_cost,
                slot_id: [],
                invite_they_pay: [],
                invite_i_pay: []
            })

            setCourtStates(data?.courts)
            setFullyBooked(data.fully_booked)

            const initialSelectedCourts = data?.courts
                .filter((court) => court.slots_reserved === 4)
                .map((court) => court.id);

            setCourts(initialSelectedCourts);    
        }
        
    }



    const handleFormUpdate = async () => {
        dispatch(showLoader())
        try {
            const response = await httpClient.put("/update-calender-event", formData);

            // if (response?.status === 200) {
            //     successPopupRef.current?.showPopUp();
            //     dispatch(setEventSuccess(`id-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`))
            //     setTimeout(() => {
            //         successPopupRef.current?.hidePopUp();
            //         confirmationPopupRef.current?.hidePopUp();
            //         setFormData(initialState)
            //         setEditConfirmDialog(false)
            //         onChange()
            //     }, 1000);
            // }

        } catch (error) {
            toast_Ref.current?.showMessage(
                "error",
                error?.response?.data?.message || "An error occurred",
                "",
                "i-notify"
            );
        }
        dispatch(hideLoader())
    }


    const onConfirm = async () => {

    };
    
    const onConfirmTokenPurchase = async () => {
        navigate("/wallet/purchase")
    }

    const onHide = () => {
        confirmationPopupRef.current.hidePopUp();
        setEditConfirmDialog(false)
    }


    const footerContentEditDialog = (
        <div className="mt-3 flex justify-content-end align-items-center gap-3">
            <Button
                label="Cancel"
                onClick={() => setEditConfirmDialog(false)}
                className="btn-secondary w-8rem"
            />
            <Button label="Ok" onClick={() => confirmationPopupRef.current.showPopUp()} className="btn-primary w-8rem" />
        </div>
    );


    const handleCheckboxChange = (courtIndex, isChecked) => {
        const updatedCourtData = courtStates.map((court, index) => {
            if (index === courtIndex) {
                return {
                    ...court,
                    slots_reserved: isChecked ? 5 : court.originalSlotsReserved,
                };
            }
            return court;
        });

        setCourtStates(updatedCourtData);

        const selectedCourtId = updatedCourtData[courtIndex].id; // Use 'id' instead of 'court_id'

        // Update selectedCourts based on the checked status
        let updatedSelectedCourts;
        if (isChecked) {
            updatedSelectedCourts = [...courts, selectedCourtId]; // Add court to courts array
            setCheckedCount(prevCount => prevCount + 1); // Increase checked count
        } else {
            updatedSelectedCourts = courts.filter((id) => id !== selectedCourtId); // Remove court from courts array
            setCheckedCount(prevCount => prevCount - 1);

            // Clear inviteTheyPayEmails if a court is unchecked
            setInviteTheyPayEmails([]);
            handleChange({ name: "invite_they_pay", value: [] });
        }

        setCourts(updatedSelectedCourts);

        // Update slot_id, excluding disabled courts
        const validSelectedCourts = updatedSelectedCourts.filter(id => {
            const court = updatedCourtData.find(court => court.id === id);
            return court && court.slots_reserved !== 4; // Exclude disabled courts
        });

        handleChange({ name: "slot_id", value: validSelectedCourts });
        updateMaxEmails(checkedCount + (isChecked ? 1 : -1));
    };



    const updateMaxEmails = (count) => {
        if (count === 0) {
            setMaxEmails(0);
        } else if (count === 1) {
            setMaxEmails(3);
        } else {
            setMaxEmails(3 + (count - 1) * 4);
        }
    };

    const isCourtChecked = (court) => court.slots_reserved === 4 || court.slots_reserved === 5;


    const headerTemplate = (type, options, event) => {
        const className = `${options.className} justify-content-space-between`;

        const toggleDetail = () => {
            options.togglerElement();
        }

        return (
            <div className={className}>
                <div className="flex align-items-center gap-2">
                    <span className="text-base font-normal flex align-items-center panel-header-i">{type == "they_pay" ? "Invite - They Pay" : "Invite - I Pay"} <i className={`ic-mark ml-3 invite-${type}`}></i></span>
                    {(!isLegacyMember || (isLegacyMember && formData.use_my_token)) && (
                        <Tooltip
                            target={`.invite-i_pay`}
                            className="slot-reserve-tooltip"
                            content={"Unlimited add-ons can be invited for free."}
                            position="top"
                            showDelay={100}
                            hideDelay={100}
                        />
                    )}
                    {type == "they_pay" &&
                        <Tooltip
                            target={`.invite-${type}`}
                            className="slot-reserve-tooltip"
                            content={
                                type === "they_pay"
                                    ? (maxEmails > 1
                                        ? `Invitations up to ${maxEmails} members can be done, and every member has to pay.`
                                        : `Select court first to invite.`)
                                    : "Unlimited add-ons can be invited for free."
                            }
                            position="top"
                            showDelay={100}
                            hideDelay={100}
                        />}
                </div>
                <div className="flex gap-5">
                    {type === "they_pay"
                        ? options.togglerElement // No need for braces around this
                        : React.cloneElement(options.togglerElement, {
                            disabled: isLegacyMember ? (isLegacyMember && !formData.use_my_token && !edit) : false
                        })
                    }
                </div>
            </div>
        );
    };




    const handlePaddleBuddyToggle = (index, type) => {
        const updatedEmails = (type === 'they_pay' ? inviteTheyPayEmails : inviteIPayEmails).map((email, i) =>
            i === index ? { ...email, buddy: !email.buddy } : email
        );

        if (type === 'they_pay') {
            setInviteTheyPayEmails(updatedEmails);
            handleChange({ name: "invite_they_pay", value: updatedEmails }); // Update formData for they_pay
        } else {
            setInviteIPayEmails(updatedEmails);
            handleChange({ name: "invite_i_pay", value: updatedEmails }); // Update formData for i_pay
        }
    };

    const searchEmail = async (event) => {
        getUsers(event.query.toLowerCase())
    };

    const handleKeyPress = (e, type) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            let email = (type === 'they_pay' ? selectedTheyPayEmail : selectedIPayEmail) || e.target.value.trim();

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            
            const matchedEmailObj = filteredEmails?.find(item => item.email === email);
            
            if (matchedEmailObj) {
                email = matchedEmailObj;
            }

            // Check if the email is a string or an object and normalize it to an object
            if (typeof email === 'string') {
                if (emailRegex.test(email)) {
                    email = { email, full_name: '', buddy: false }; // If it's just an email string, wrap it in an object
                } else {
                    toast_Ref.current?.showMessage(
                        "error",
                        "Invalid Email",
                        "",
                        "ic-error-notify"
                    );
                    return;
                }
            }

            // Ensure the object has a valid email field before proceeding
            if (emailRegex.test(email?.email)) {
                const setInviteEmails = (type === 'they_pay') ? setInviteTheyPayEmails : setInviteIPayEmails;
                const prevEmails = (type === 'they_pay') ? inviteTheyPayEmails : inviteIPayEmails;

                setInviteEmails((prevEmails) => {
                    const isDuplicate = prevEmails.some((item) => item.email === email.email);

                    if (!isDuplicate && (prevEmails.length < maxEmails || type === "i_pay")) {
                        const updatedEmails = [...prevEmails, {
                            email: email.email,
                            full_name: email.full_name || email.email, // Default full_name to email if empty
                            buddy: email.buddy,
                            initial_buddy: email.buddy
                        }];

                        handleChange({
                            name: type === 'they_pay' ? "invite_they_pay" : "invite_i_pay",
                            value: updatedEmails
                        });

                        return updatedEmails;
                    } else if (isDuplicate) {
                        toast_Ref.current?.showMessage(
                            "error",
                            "Email Already Exists",
                            "",
                            "ic-error-notify"
                        );
                        return prevEmails;
                    } else if (prevEmails.length >= maxEmails) {
                        toast_Ref.current?.showMessage(
                            "error",
                            maxEmails < 1 ? "Please Select Court First" : `Only ${maxEmails} invites are allowed. Select another court for more invites.`,
                            "",
                            "ic-error-notify"
                        );
                        return prevEmails;
                    }
                });

                type === 'they_pay' ? setSelectedTheyPayEmail(null) : setSelectedIPayEmail(null);
            } else {
                toast_Ref.current?.showMessage(
                    "error",
                    "Invalid Email",
                    "",
                    "ic-error-notify"
                );
            }
        }
    };


    const emailItemTemplate = (emailObj) => {
        return (
            <div>
                {emailObj.buddy ? emailObj.full_name : emailObj.email}
            </div>
        );
    };

    const handleAutoCompleteChange = (e, type) => {
        if (type == "they_pay") {
            setSelectedTheyPayEmail(e.value);
        } else {
            setSelectedIPayEmail(e.value);
        }
    };

    const removeEmail = (emailToRemove, type) => {
        const updatedEmails = (type === "they_pay")
            ? inviteTheyPayEmails.filter(email => email.email !== emailToRemove)
            : inviteIPayEmails.filter(email => email.email !== emailToRemove);

        if (type === "they_pay") {
            setInviteTheyPayEmails(updatedEmails);
            handleChange({ name: "invite_they_pay", value: updatedEmails }); // Update formData for they_pay
        } else {
            setInviteIPayEmails(updatedEmails);
            handleChange({ name: "invite_i_pay", value: updatedEmails }); // Update formData for i_pay
        }
    };

    const setUseMyToken = () => {
        setUseToken(!useToken)
        handleChange({ name: "use_my_token", value: !useToken })
        setInviteIPayEmails([])
    }



    return (
        <>
            <ConfirmationPopup
                ref={confirmationPopupRef}
                closable={true}
                title="Confirm"
                message={formData.is_all ? "Are you sure you want to update the reservation slots?" : "Are you sure you want to update this reservation slot?"}
                onConfirm={onConfirm}
                onCancel={onHide}
                onHide={onHide}
            />
            <ConfirmationPopup
                ref={tokenPurchasePopupRef}
                closable={true}
                title="Confirm"
                message={"You have insufficient tokens. Would you like to purchase more tokens?"}
                onConfirm={onConfirmTokenPurchase}
                onCancel={() => tokenPurchasePopupRef.current.hidePopUp()}
                onHide={() => tokenPurchasePopupRef.current.hidePopUp()}
            />
            <Dialog
                header={eventName}
                visible={editConfirmDialog}
                draggable={false}
                className="success_popup edit_is_all_dialog"
                style={{ width: "33vw" }}
                onHide={() => setEditConfirmDialog(false)
                }
                footer={footerContentEditDialog}
                closable={true}
            >
                <div className="flex justify-content-start align-items-center gap-2">
                    <RadioButton
                        inputId="is_all"
                        name="is_all"
                        value={false}
                        onChange={(value) => handleChange({ name: "is_all", value: value.value })}
                        checked={formData.is_all === false}
                    />
                    <label htmlFor="never" className="ml-2 text-base">This event</label>
                    <RadioButton
                        inputId="is_all"
                        name="is_all"
                        value={true}
                        className="ml-3"
                        onChange={(value) => handleChange({ name: "is_all", value: value.value })}
                        checked={formData.is_all === true}
                    />
                    <label htmlFor="never" className="ml-2 text-base">This & Following Events</label>
                </div>
            </Dialog>
            <VivToast ref={toast_Ref} />
            <form className="max-w-full mx-auto viv-slot-reserve-dialog" onSubmit={onSubmit}>
                <div className="grid cs-border-btm mb-4">
                    <div className="event-view-schedule gap-2 col-12 md:col-4">
                        <i className="ic-reservation"></i>
                        <div className="flex flex-column">
                            <p className="reserve-headr">Reservation Price</p>
                            <span>{data?.token_cost} Tokens Per Court</span>
                        </div>
                    </div>
                    <div className="event-view-schedule gap-2 col-12 md:col-4">
                        <i className="ic-clock"></i>
                        <div className="flex flex-column">
                            <p className="reserve-headr">Time</p>
                            <span>{formatDateString(data?.occurrence_date_formated)} <br /> {formatTimeRange(data?.start_time, data?.end_time)}</span>
                        </div>
                    </div>
                    <div className="event-view-schedule gap-2 col-12 md:col-4">
                        <i className="ic-location"></i>
                        <div className="flex flex-column">
                            <p className="reserve-headr">Location</p>
                            <span>{data.location_name}</span>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="grid event-view-schedule gap-2 col-12 md:col-6">
                        <i className="ic-court col-1"></i>
                        <div className="flex flex-column col-10">
                            <p className="reserve-headr">Available Courts</p>
                            <div className="grid reserve-available-court">
                                {courtStates.map((court, index) => (
                                    <div key={court.court_id} className="col-3 flex gap-1 mt-2">
                                        <Checkbox
                                            inputId={`court-${court.court_id}`}
                                            checked={isCourtChecked(court)}
                                            onChange={(e) => handleCheckboxChange(index, e.checked)}
                                            disabled={court.slots_reserved == 4}
                                        />
                                        <label htmlFor={`court-${court.court_id}`}>
                                            {"0" + court.court_number.match(/\d+/)[0]}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {!fullyBooked && (
                        <>
                            {isLegacyMember && <div className="event-view-schedule gap-2 col-6">
                                <i className="ic-orangecoins"></i>
                                <div className="flex flex-column">
                                    <p className="reserve-headr flex align-items-center">Use My Tokens <i className="ic-mark ml-3 use-my-tokens"></i></p>
                                    <Tooltip target={`.use-my-tokens`} className="slot-reserve-tooltip" content={"On active you want to reserve with tokens"} position="top" showDelay={100} hideDelay={100} />
                                    <div className="grid reserve-available-court">
                                        <div className="col-5 flex gap-1 mt-2">
                                            <Checkbox
                                                checked={formData?.use_my_token}
                                                onChange={setUseMyToken}
                                                disabled={edit}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            <div className="col-12">
                                <Panel headerTemplate={(options) => headerTemplate("they_pay", options, event)} toggleable className="event-panel" collapseIcon='ic-chev-up' expandIcon='ic-chev-down' collapsed={true}>
                                    <div className="grid inivte-they-pay-location-schedule">
                                        <div className="col-12">
                                            <AutoComplete
                                                value={selectedTheyPayEmail}
                                                disabled={edit}
                                                field="email"
                                                suggestions={filteredEmails}
                                                completeMethod={searchEmail}
                                                onChange={(e) => handleAutoCompleteChange(e, "they_pay")}
                                                itemTemplate={emailItemTemplate}
                                                placeholder="Enter the email to invite"
                                                className="w-full"
                                                onKeyPress={(e) => handleKeyPress(e, "they_pay")}
                                            />
                                        </div>
                                        {inviteTheyPayEmails?.map((emailObj, index) => (
                                            <div className="col-12 md:col-6 p-1" key={index}>
                                                <div className="flex justify-content-between align-items-center gap-2" key={index}>
                                                    <div className="flex justify-content-start align-items-center gap-1">
                                                        <Avatar
                                                            className="custom-avatar-pay"
                                                            label={emailObj?.paddleBuddy ? emailObj?.full_name?.charAt(0).toUpperCase() : emailObj?.email?.charAt(0).toUpperCase()}
                                                            style={{
                                                                backgroundColor: avatarColors[index % avatarColors.length],
                                                                color: '#fff'
                                                            }}
                                                            shape="circle"
                                                        />
                                                        <p>{emailObj.initial_buddy ? emailObj.full_name : emailObj.email}</p>
                                                    </div>
                                                    <div className="flex justify-content-start align-items-center gap-1">
                                                        {!edit ? (
                                                            <>
                                                                {!emailObj.initial_buddy && (
                                                                    <>
                                                                        <i
                                                                            className={`ic-circle-plus cursor-pointer buddy_${index} ${!emailObj.buddy && "icon-color-differ"}`}
                                                                            onClick={() => handlePaddleBuddyToggle(index, "they_pay")}
                                                                        ></i>
                                                                        <Tooltip
                                                                            target={`.buddy_${index}`}
                                                                            className="slot-reserve-tooltip"
                                                                            content={"Add as Paddle Buddy"}
                                                                            position="top"
                                                                            showDelay={100}
                                                                            hideDelay={100}
                                                                        />
                                                                    </>
                                                                )}
                                                                <i
                                                                    className="ic-close close-icon-pay cursor-pointer"
                                                                    onClick={() => removeEmail(emailObj.email, "they_pay")}
                                                                ></i>
                                                            </>
                                                        ) : (
                                                                <>
                                                                    <Chip label={emailObj?.isPaid} className={`${emailObj?.isPaid}`} />
                                                                </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Panel>
                            </div>
                            <div className="col-12">
                                <Panel headerTemplate={(options) => headerTemplate("i_pay", options, event)} toggleable className={`event-panel ${(isLegacyMember && !formData.use_my_token && !edit) && "panel-disabled"}`} collapseIcon='ic-chev-up' expandIcon='ic-chev-down' collapsed={true}>
                                    <div className="grid inivte-they-pay-location-schedule">
                                        <div className="col-12">
                                            <AutoComplete
                                                value={selectedIPayEmail}
                                                field="email"
                                                disabled={isLegacyMember ? (isLegacyMember && !formData.use_my_token) : edit}
                                                suggestions={filteredEmails}
                                                completeMethod={searchEmail}
                                                onChange={(e) => handleAutoCompleteChange(e, "i_pay")}
                                                itemTemplate={emailItemTemplate}
                                                placeholder="Enter the email to invite"
                                                className="w-full"
                                                onKeyPress={(e) => handleKeyPress(e, "i_pay")}
                                            />
                                        </div>
                                        {inviteIPayEmails?.map((emailObj, index) => (
                                            <div className="col-12 md:col-6" key={`i_pay_${index}`}>
                                                <div className="flex justify-content-between align-items-center gap-2" key={index}>
                                                    <div className="flex justify-content-start align-items-center gap-1">
                                                        <Avatar
                                                            className="custom-avatar-pay"
                                                            label={emailObj.paddleBuddy ? emailObj.full_anme.charAt(0).toUpperCase() : emailObj.email.charAt(0).toUpperCase()}
                                                            style={{
                                                                backgroundColor: avatarColors[index % avatarColors.length],
                                                                color: '#fff'
                                                            }}
                                                            shape="circle"
                                                        />
                                                        <p>{emailObj.initial_buddy ? emailObj.full_name : emailObj.email}</p>
                                                    </div>
                                                    <div className="flex justify-content-start align-items-center gap-1">
                                                        {!emailObj.initial_buddy && <i
                                                            className={`ic-circle-plus cursor-pointer ${!emailObj.buddy && "icon-color-differ"}`}
                                                            onClick={() => handlePaddleBuddyToggle(index, "i_pay")}
                                                        ></i>}
                                                        <i className="ic-close close-icon-pay cursor-pointer" onClick={() => removeEmail(emailObj.email, "i_pay")}></i>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Panel>
                            </div>
                        </>
                    )}
                </div>
                {footerContent}
            </form>
        </>
    );
}
