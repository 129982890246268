import React, { useEffect, useRef, useState } from "react";
import httpClient from "../../_util/api";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import AddEvent from "../../pages/Franchise/calendar/addEvent";
import EventListDialog from "../../pages/Franchise/calendar/events-list-dialog";
import EventViewDialog from "../../pages/Franchise/calendar/event-view-dialog";
import { Tooltip } from 'primereact/tooltip';
import SlotReserveDialog from "../../pages/Franchise/calendar/slot-reserve-dialog";
import PrivateSlotReserveDialog from "../../pages/Franchise/calendar/private-slot-reserve-dialog";
import WaitListConfirmation from "../../shared/SuccessPopup";
import { hideLoader, setBadgeValue, setEventSuccess, showLoader } from "../../redux/mainSlice";
import VivToast from "../../shared/VivitechToast";
import SlotListDialog from "../../pages/Franchise/calendar/slot-list-dialog";
import { isMobileOnly as isMobile } from "react-device-detect";
import ReservationCancelDialog from "../../pages/Franchise/calendar/reservation-cancel-dialog";

const WeekCalander = ({ calendarDate, filteredEventData, path }) => {
    const [currentDate, setCurrentDate] = useState(`${new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()}-${new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}-${new Date()?.getFullYear()}`);
    const [weekDates, setWeekDates] = useState([]);
    const eventChange = useSelector((state) => state.mainSlice.eventSuccess);
    const locationId = useSelector((state) => state.mainSlice.locationId);
    const [addEvent, setAddEvent] = useState(false)
    const WaitListConfirmationRef = useRef(null);
    const [eventListShow, setEventListShow] = useState(false)
    const dispatch = useDispatch();
    const toast_Ref = useRef(null);
    const [eventViewShow, setEventViewShow] = useState(false)
    const [eventPopupData, setEventPopupData] = useState([])
    const [slotReserveShow, setSlotReserveShow] = useState(false)
    const [cancelSlotReserveShow, setCancelSlotReserveShow] = useState(false)
    const [slotListShow, setSlotListShow] = useState(false)
    const [privateSlotReserveShow, setPrivateSlotReserveShow] = useState(false)
    const [hour, setHour] = useState([])
    const [createDate, setCreateDate] = useState()
    const [weekDays, setWeekDays] = useState(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']);
    const [weekHours, setWeekHours] = useState([
        { key: '1:00 AM', label: '1 A.M' },
        { key: '2:00 AM', label: '2 A.M' },
        { key: '3:00 AM', label: '3 A.M' },
        { key: '4:00 AM', label: '4 A.M' },
        { key: '5:00 AM', label: '5 A.M' },
        { key: '6:00 AM', label: '6 A.M' },
        { key: '7:00 AM', label: '7 A.M' },
        { key: '8:00 AM', label: '8 A.M' },
        { key: '9:00 AM', label: '9 A.M' },
        { key: '10:00 AM', label: '10 A.M' },
        { key: '11:00 AM', label: '11 A.M' },
        { key: '12:00 PM', label: '12 P.M' },
        { key: '1:00 PM', label: '1 P.M' },
        { key: '2:00 PM', label: '2 P.M' },
        { key: '3:00 PM', label: '3 P.M' },
        { key: '4:00 PM', label: '4 P.M' },
        { key: '5:00 PM', label: '5 P.M' },
        { key: '6:00 PM', label: '6 P.M' },
        { key: '7:00 PM', label: '7 P.M' },
        { key: '8:00 PM', label: '8 P.M' },
        { key: '9:00 PM', label: '9 P.M' },
        { key: '10:00 PM', label: '10 P.M' },
        { key: '11:00 PM', label: '11 P.M' },
        { key: '12:00 AM', label: '12 A.M' }
    ]);
    const [events, setEvents] = useState([]);



    const getEventSlots = async (fromDate, toDate) => {
        dispatch(showLoader())
        try {
            const resp = await httpClient.post(`${path == "my-reservation" ? '/my-reservations' : '/event-slots'}`, {
                event_type_id: filteredEventData,
                location_id: locationId,
                frequency: "week",
                from_date: fromDate,
                to_date: toDate
            });
            setEvents(resp.data.data)
            getNotificationsCount()
        } catch (error) {
            toast_Ref.current?.showMessage(
                "error",
                error?.response?.data?.message || "An error occurred",
                "",
                "ic-error-notify"
            );
        }
        dispatch(hideLoader())
    }
    
    const getNotificationsCount = async () => {
        let apiResp = await httpClient.get("whomi")

        if (apiResp?.status == 200) {
            dispatch(setBadgeValue(apiResp.data.data.user.unread ? apiResp.data.data.user.unread : 0))
        }
    }

    useEffect(() => {
        locationId && setWeekDate(calendarDate)

    }, [eventChange, calendarDate, filteredEventData, locationId]);


    const handleShowMoreEvents = (data, event) => {
        event.stopPropagation()
        setEventPopupData(data)

        if (path == "location-schedule" || path == "my-reservation") {
            setSlotListShow(true)
        } else {
            setEventListShow(true)
        }

    }

    const handleEventShow = (data, event) => {
        event.stopPropagation()
        console.log(data);
        
        setEventPopupData(data)
        if (path == 'location-schedule' || path == 'my-reservation') {
            if (data && data.event_type_separate == 'scheduled_play') {
                if (data?.fully_booked) {
                    WaitListConfirmationRef.current.showPopUp()
                } else {
                    if (path == 'location-schedule') {
                        setSlotReserveShow(true)
                    } else {
                        setCancelSlotReserveShow(true)
                    }
                }
            } else {
                setPrivateSlotReserveShow(true)
            }
        } else {
            setEventViewShow(true)
        }

    }

    const handleEventCreateFromCalendar = (date, hour) => {
        setCreateDate(`${String(date.date).padStart(2, '0')}-${String(date.month).padStart(2, '0')}-${date.year}`)
        setHour(hour.key)
        setAddEvent(true)

    }

    const onConfirmWaitList = async () => {
        dispatch(showLoader())

        try {
            const resp = await httpClient.post("/wait-list", {
                event_id: eventPopupData.event_id,
                occurrence_date: eventPopupData.occurrence_date,
                start_time: eventPopupData.start_time,
                end_time: eventPopupData.end_time
            });

            if (resp.status == 200) {
                toast_Ref.current?.showMessage(
                    "success",
                    "Waitlist Joined",
                    "",
                    "ic-square-check"
                )
                dispatch(setEventSuccess(`id-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`))
                setTimeout(() => {
                    WaitListConfirmationRef.current.hidePopUp()
                }, 500)
            }

        } catch (error) {
            toast_Ref.current?.showMessage(
                "error",
                error?.response?.data?.message || "An error occurred",
                "",
                "ic-error-notify"
            );
        }
        dispatch(hideLoader())
    }


    const setWeekDate = async (date) => {
        let curr = new Date(date);

        let day = curr.getDay(); // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
        let first = curr.getDate() - day; // Calculate the first day of the week (Sunday)
        let last = first + 6; // Calculate the last day of the week (Saturday)

        let firstday = new Date(curr.getFullYear(), curr.getMonth(), first);
        let lastday = new Date(curr.getFullYear(), curr.getMonth(), last);

        let dates_arr = [];
        let tempDate = new Date(firstday);

        // Populate dates_arr with each day of the week
        while (tempDate <= lastday) {
            dates_arr.push({
                date: new Date(tempDate).getDate(),
                month: new Date(tempDate).getMonth() + 1,
                year: new Date(tempDate).getFullYear(),
            });
            tempDate.setDate(tempDate.getDate() + 1); // Move to the next day
        }
        setWeekDates(dates_arr);

        let formattedFirstDate = formatDate(firstday);
        let formattedLastDate = formatDate(lastday);

        getEventSlots(formattedFirstDate, formattedLastDate);
    }

    const formatDate = (date) => {
        let day = String(date.getDate()).padStart(2, '0');
        let month = String(date.getMonth() + 1).padStart(2, '0');
        let year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };
    
    function formatTimeRange(startTime, endTime) {
        const formatTime = (time) => {
            // Convert time string (e.g., "02:00 AM") to a Date object
            const [hour, minutePart] = time.split(':');
            const [minute, period] = minutePart.split(' ');

            // Convert hour to a number and remove leading zeros
            let hourNum = parseInt(hour, 10);

            // Format hour without leading zero for 12-hour format
            return `${hourNum} ${period}`;
        };

        // Format both start time and end time
        const formattedStartTime = formatTime(startTime);
        const formattedEndTime = formatTime(endTime);

        return `${formattedStartTime} - ${formattedEndTime}`;
    }

    const renderEventsForSlot = (date, hour) => {

        const dayEvents = events?.find(event => event.date === date);
        if (!dayEvents) return null;

        const slotEvents = dayEvents?.slots?.find(slot => slot.time === hour.key);

        if (!slotEvents) return null;

        return (<>
            {slotEvents.events.slice(0, 2).map((e, index) => (
                <div className={`event-name-${e.event_id}-${e.occurrence_date} ${isMobile ? 'event-card-mob': 'event-card'} ${e.fully_booked ? 'danger-bg' : e.event_type_separate === "scheduled_play" ? 'schedule-play-bg' : 'primary-bg'} p-1 lf-${index} ${slotEvents?.events?.length > 2 ? '' : 'week-view-slot'}`} key={index} onClick={(ev) => handleEventShow(e, ev)
                }>
                    <Tooltip target={`.event-name-${e.event_id}-${e.occurrence_date}`} content={e.event_type_name} position="top" showDelay={100} hideDelay={100} />
                    <p className="title">{isMobile ? e.event_type_name.substring(0,4) + '..' : e.event_type_name}</p>
                    <div className="event-info-container flex align-items-center justify-content-between">
                        <div className="event-info">
                            <p>{formatTimeRange(e.start_time,e.end_time)}</p>
                            <p>{e.token_cost} <img src="/images/coin.svg" alt="coin" /></p>
                        </div>
                        <div>
                            {e.count && <div className="court-count-wraper">
                                <p className="pb-1 pl-2 pr-2 pt-1">{e.count}</p>
                            </div>}
                        </div>
                    </div>
                </div>
            ))}
            {slotEvents.events.length > 2 && (
                <div className={`${isMobile ? 'event-card-mob': 'event-card'} primary-bg p-1 lf-3 more-events ${(path === 'location-schedule' || path === 'my-reservation') && "view-more-events"}`} onClick={(ev) => handleShowMoreEvents(slotEvents.events, ev)}>
                    <p className="title-more">{slotEvents.events.length - 2}+</p>
                </div>
            )}
        </>
        );
    };

    return (<>
        <EventListDialog visible={eventListShow} onChange={() => setEventListShow(false)} data={eventPopupData} />
        <SlotListDialog visible={slotListShow} onChange={() => setSlotListShow(false)} data={eventPopupData} />
        <EventViewDialog visible={eventViewShow} onChange={() => setEventViewShow(false)} data={eventPopupData} />
        <AddEvent visible={addEvent} onChange={() => setAddEvent(false)} id={null} time={hour} createDate={createDate} />
        <SlotReserveDialog visible={slotReserveShow} onChange={() => setSlotReserveShow(false)} data={eventPopupData} />
        <ReservationCancelDialog visible={cancelSlotReserveShow} onChange={() => setCancelSlotReserveShow(false)} data={eventPopupData} />
        <PrivateSlotReserveDialog visible={privateSlotReserveShow} onChange={() => setPrivateSlotReserveShow(false)} data={eventPopupData} />
        <VivToast ref={toast_Ref} />
        <WaitListConfirmation
            ref={WaitListConfirmationRef}
            closable={true}
            title=""
            messageTitle={"All Courts are booked. We have a waiting list of 12 member. Do you want to join waitlist?"}
            onConfirm={onConfirmWaitList}
            onCancel={() => WaitListConfirmationRef.current.hidePopUp()}
            onHide={() => WaitListConfirmationRef.current.hidePopUp()} />
        <div className="week-calendar pl-7">
            <div className="grid m-0 header-row">
                {
                    weekDates.map((date, ind) => (
                        <div className="col calendar-header" key={ind}>
                            <p className={`${`${String(date.date).padStart(2, '0')}-${String(date.month).padStart(2, '0')}-${date.year}` == currentDate ? 'p-date-active' : 'p-date'}`}>{date.date}</p>
                            <p className="p-day">{weekDays[ind]}</p>
                        </div>
                    ))
                }
            </div>


            {weekHours.map((hour, ind) => (
                <div className={`grid m-0 calendar-row`} key={ind}>
                    <span className="calendar-row-cell-time">{hour.label}</span>
                    {weekDates.map((date, dateIndex) => (
                        <div className={`col calendar-row-cell ${isMobile ? 'flex-column': ''}`} key={dateIndex} onClick={(e) => path == "calendar" && handleEventCreateFromCalendar(date, hour)
                        }>
                            {renderEventsForSlot(`${date.date < 10 ? '0' + date.date : date.date}-${date.month >= 10 ? date.month : `0${date.month}`}-${date.year}`, hour)}
                        </div>
                    ))}
                </div>
            ))}



        </div>
    </>);
}

export default WeekCalander;