import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { InputMask } from "primereact/inputmask";
import { Chips } from "primereact/chips";
import { InputTextarea } from "primereact/inputtextarea";
import { Password } from "primereact/password";
import "./index.scss";
import { Calendar } from "primereact/calendar";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const FormInput = (props) => {
  const [password, setPassword] = useState('');
  const [passwordStrengthLabel, setPasswordStrengthLabel] = useState('');
  const [phone, setPhone] = useState("");

 

  const handleChange = (e) => {
    if(props?.showMeter){
      setPassword(e);
      let strength = calculateStrength(e);
      setPasswordStrengthLabel(getStrengthLabel(strength));
    }
    if (props.onchange) {
      props.onchange(e);
    }
  };

  const handleFocus = () => {
    if (props.onFocus) {
      props.onFocus();
    }
  };

  const handleClick = (e) => {
    if (props.onClick) {
      props.onClick(e);
    }
  };

  const calculateStrength = (password) => {
    let strength = 0;
    if (password.length > 7) strength += 1;
    if (password.match(/[a-z]+/)) strength += 1;
    if (password.match(/[A-Z]+/)) strength += 1;
    if (password.match(/[0-9]+/)) strength += 1;
    if (password.match(/[!@#$%^&(){}\|]+/)) strength += 1;
    return strength;
  };

  const getStrengthLabel = (strength) => {
    switch (strength) {
      case 0:
        return 'Too Weak';
      case 1:
        return 'Weak';
      case 2:
        return 'Fair';
      case 3:
        return 'Good';
      case 4:
        return 'Good';
      case 5:
        return 'Strong';
      default:
        return '';
    }
  };

  return (
    <div
      className={`flex flex-column shis-input gap-2 w-full ${
        props?.inputContainerClass ? props?.inputContainerClass : ""
      }`}>
      {props.title && (
        <label>
          {props.title}{" "}
          {props?.required && <span className="text-danger">*</span>}{" "}
        </label>
      )}
      {props.inputtype == "number" && (
        <InputNumber
          useGrouping={false}
          min={0}
          mode={props.mode ? props.mode : "decimal"}
          currency={props.currency ? props.currency : "USD"}
          maxLength={props.maxLength ? props.maxLength : null}
          placeholder={props.placeholder ? props.placeholder : "Type here"}
          disabled={props.isDisabled ? true : false}
          onChange={(e) => {
            props?.maxLength
              ? handleChange(e.value?.toString().substring(0, props.maxLength))
              : handleChange(e.value);
          }}
          onFocus={handleFocus}
          value={props.value && props.value}
          className={`w-full ${props.styleclass || ""} ${
            props.error && "error-input"
          }`}
        />
      )}

      {props.inputtype == "password" && (
        <>
          <Password
            placeholder={props.placeholder ? props.placeholder : "Type here"}
            value={props.value && props.value}
            className={`w-full ${props.styleclass || ""} ${
              props.error && "error-input"
            } `}
            disabled={props.isDisabled ? true : false}
            onChange={(e) => handleChange(e.target.value)}
            name={props.name}
            feedback={false}
            toggleMask
          />
          {props?.showMeter && (
            <div className={`pwd-meter-container ${props.meterClass || ""} `}>
              <div className="w-full flex gap-1">
                <div
                  className={`pwd_strength_box ${
                    passwordStrengthLabel == "Too Weak"
                      ? "bg-tooweak"
                      : passwordStrengthLabel == "Weak"
                      ? "bg-weak"
                      : passwordStrengthLabel == "Fair"
                      ? "bg-fair"
                      : passwordStrengthLabel == "Good"
                      ? "bg-good"
                      : passwordStrengthLabel == "Strong"
                      ? "bg-strong"
                      : ""
                  }`}></div>
                <div
                  className={`pwd_strength_box ${
                    passwordStrengthLabel == "Weak"
                      ? "bg-weak"
                      : passwordStrengthLabel == "Fair"
                      ? "bg-fair"
                      : passwordStrengthLabel == "Good"
                      ? "bg-good"
                      : passwordStrengthLabel == "Strong"
                      ? "bg-strong"
                      : ""
                  }`}></div>
                <div
                  className={`pwd_strength_box ${
                    passwordStrengthLabel == "Fair"
                      ? "bg-fair"
                      : passwordStrengthLabel == "Good"
                      ? "bg-good"
                      : passwordStrengthLabel == "Strong"
                      ? "bg-strong"
                      : ""
                  }`}></div>
                <div
                  className={`pwd_strength_box ${
                    passwordStrengthLabel == "Good"
                      ? "bg-good"
                      : passwordStrengthLabel == "Strong"
                      ? "bg-strong"
                      : ""
                  }`}></div>
                <div
                  className={`pwd_strength_box ${
                    passwordStrengthLabel == "Strong" ? "bg-strong" : ""
                  }`}></div>
              </div>
              <div className="w-full text-right mt-2">
                <p
                  className={`pwd_strength_label ${
                    passwordStrengthLabel == "Too Weak"
                      ? "bg-tooweak"
                      : passwordStrengthLabel == "Weak"
                      ? "bg-weak"
                      : passwordStrengthLabel == "Fair"
                      ? "bg-fair"
                      : passwordStrengthLabel == "Good"
                      ? "bg-good"
                      : passwordStrengthLabel == "Strong"
                      ? "bg-strong"
                      : ""
                  }`}>
                  {passwordStrengthLabel}
                </p>
              </div>
              <div className="w-full mt-2">
                {passwordStrengthLabel == "Too Weak" && (
                  <p className="pwd_strength_label">
                    Too weak (Should have 8 characters limit, upper case,
                    alphanumeric, and special characters.)
                  </p>
                )}
                {passwordStrengthLabel == "Weak" && (
                  <p className="pwd_strength_label">
                    Weak (Should include upper case, alphanumeric, and special
                    characters.)
                  </p>
                )}
                {passwordStrengthLabel == "Fair" && (
                  <p className="pwd_strength_label">
                    Fair (Should include alphanumeric, and special characters.)
                  </p>
                )}
                {passwordStrengthLabel == "Good" && (
                  <p className="pwd_strength_label">
                    Good (Should include special characters.)
                  </p>
                )}
              </div>
            </div>
          )}
        </>
      )}

{props.inputtype === "phone" && (
  <PhoneInput
  country={"mx"}
  onlyCountries={["ca","us", "mx"]}
  // enableAreaCodes={true}
  enableSearch={true}
  placeholder={props.placeholder ? props.placeholder : "+999 9999999"}
  disabled={props.isDisabled ? true : false}
  // onFocus={handleFocus}
  onChange={(value) => props.purchase_contact? props.setPhoneValue(value) : handleChange(value)}  
  value={props.value || phone}
  dropdownClass="countrycode-dropdown" 
  buttonClass="countrycode-button"
  inputClass="p-inputtext p-component phone-input"      
  countryCodeEditable={false}
          // disableInitialCountryGuess={true}
  disableCountryGuess={props.isDisabled ? false : true}
/>
      )}

    {/*   {props.inputtype == "phone" && (
        <InputMask
          className={`w-full ${props.styleclass || ""} ${
            props.error && "error-input"
          }`}
          mask="+9 999 9999999"
          placeholder={props.placeholder ? props.placeholder : "+999 9999999"}
          disabled={props.isDisabled ? true : false}
          onChange={(e) => handleChange(e.target.value)}
          onFocus={handleFocus}
          value={props.value && props.value}
        />
      )} */}
      
      {props.inputtype == "calendar" && (
        <Calendar
          className={`w-full ${props.styleclass || ""} ${
            props.error && "error-input"
            }`}
          dateFormat={props.dateFormat ? props.dateFormat : ""}
          placeholder={props.placeholder ? props.placeholder : "Enter Value"}
          disabled={props.isDisabled ? true : false}
          onChange={(e) => handleChange(e.target.value)}
          onFocus={handleFocus}
          value={props.value && props.value}
          minDate={props.minDate ? props.minDate : ""}
        />
      )}

      {props.inputtype == "chip" && (
        <Chips
          value={props.value}
          placeholder={props.placeholder ? props.placeholder : "Type here"}
          onChange={(e) => handleChipsChange(e.value)}
        />
      )}

      {props.inputtype == "textarea" && (
        <InputTextarea
          value={props.value}
          placeholder={props.placeholder ? props.placeholder : "Type here"}
          onChange={(e) => handleChange(e.target.value)}
          disabled={props.isDisabled ? true : false}
          rows={2}
          cols={5}
          maxLength={props.maxLength ? props.maxLength : null}
          className={props.error && "error-input"}
        />
      )}

      {(!props.inputtype ||
        props.inputtype == "email" ||
        props.inputtype == "text") && (
        <InputText
          className={`w-full ${props.styleclass || ""} ${
            props.error && "error-input"
          } ${
            props.isSubmit && (props.value == "" || props.value == undefined)
              ? "p-invalid"
              : ""
          }`}
          placeholder={props.placeholder ? props.placeholder : "Type here"}
          type={props.inputtype ? props.inputtype : "text"}
          disabled={props.isDisabled ? true : false}
          onClick={(e) => handleClick(e.target.value)}
          onChange={(e) => handleChange(e.target.value)}
          onFocus={handleFocus}
          name={props.name}
          value={props.value && props.value}
          keyfilter={props.keyFilter ? props.keyFilter : null}
          maxLength={props.maxLength ? props.maxLength : 50}
        />
      )}
    </div>
  );
};

export default FormInput;
