import React, { useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Panel } from 'primereact/panel';
import "./style.scss";
import httpClient from "../../../../_util/api";
import AddEvent from "../addEvent";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import VivToast from "../../../../shared/VivitechToast";
import { useDispatch } from "react-redux";
import { hideLoader, setEventSuccess, showLoader } from "../../../../redux/mainSlice";
import ConfirmationPopup from "../../../../shared/ConfirmationPopup";

const EventListDialog = ({ visible, onChange, data, type, onDialogClose }) => {
    const [eventsInfo, setEventsInfo] = useState([])
    const toast_Ref = useRef(null);
    const [editData, setEditData] = useState([])
    const [showAddEvent, setShowAddEvent] = useState(false);
    const [is_all,setis_all] = useState(false)
    const [deleteConfirmDialog,setDeleteConfirmDialog] = useState(false)
    const [deleteData,setDeleteData] = useState(false)
    const confirmationPopupRef = useRef(null);
    const dispatch = useDispatch();
    const headerContent = ()=> (
        <div className="event-dialog-header">
            <h1 className="event-dialog-header-h1">All Event</h1>
        </div>
    )
    
        const onLoad = () => {
            setEventsInfo(data)
        }

    const headerTemplate = (options,event) => {
        const className = `${options.className} justify-content-space-between`;

        const toggleDetail = ()=>{
            options.togglerElement();
        }


        
        

        

        return (
            <div className={className}>
                <div className="flex align-items-center gap-2">
                    <span className="event-title">{event.event_type_name}</span>
                </div>
                <div className="flex gap-5">
                <div className="flex flex-row">
                        <p className="pl-2 pr-2  align-self-center token_cost ">{ event.token_cost}</p>
                        <img className=" align-self-center" src="/images/tokenicon.svg" alt="" />
                        
                    </div>
              {event.count && <div className="court-count-wraper">
                <p className="">{event.count}</p>
              </div>}
                  
                    {options.togglerElement}
                </div>
            </div>
        );
    };
    
    const onEditEvent = async (data) => {
        dispatch(showLoader())
        try {
        const resp = await httpClient.post("/edit-slot", {
            event_id: data.event_id,
            occurrence_date: data.occurrence_date,
            start_time: data.start_time,
            end_time: data.end_time,
            court_id: data?.court_id
      });
      
        if (resp?.status === 200) {
            setEditData(resp.data.data)
            setShowAddEvent(true)
          onChange()
          if (onDialogClose) {
            onDialogClose()
          }
      }
      
    } catch (error) {
      toast_Ref.current?.showMessage(
        "error",
        error?.response?.data?.message || "An error occurred",
        "",
        "ic-error-notify"
      );
      }
      dispatch(hideLoader())
    }
    
    const onDeleteEvent = async (data) => {
      setDeleteData(data)
      if (data.is_recurrent === 1 || data.is_recurrent === true) {
           setDeleteConfirmDialog(true) 
      } else {
        confirmationPopupRef.current.showPopUp()
      }
  }
  
  const onCheckIsAll = async () => {
    confirmationPopupRef.current.showPopUp()
  }
  
  const onHide = () => {
    confirmationPopupRef.current?.hidePopUp()
    onChange()
  }
    
  const onConfirm = async () => {   
      dispatch(showLoader())
        try {
        const resp = await httpClient.post("/delete-slot", {
            event_id: deleteData.event_id,
            occurrence_date: deleteData.occurrence_date,
            start_time: deleteData.start_time,
            end_time: deleteData.end_time,
            court_id: deleteData?.court_id,
            is_all:is_all
        });
            
            if (resp?.status === 200) {            
            dispatch(setEventSuccess(`id-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`))
            setDeleteConfirmDialog(false)
            toast_Ref.current?.showMessage(
              "success",
              "Reservation slot deleted successfully",
              "",
              "ic-square-check"
            )
            setTimeout(() => {
              confirmationPopupRef.current?.hidePopUp();
              setis_all(false)
              onChange()
              if (onDialogClose) {
                onDialogClose()
              }
      }, 1000);
      }
    } catch (error) {
      toast_Ref.current?.showMessage(
        "error",
        error?.response?.data?.message || "An error occurred",
        "",
        "ic-error-notify"
      );
    }
    dispatch(hideLoader())
    }
    
    
      const footerContentEditDialog = (
    <div className="mt-3 flex justify-content-end align-items-center gap-3">
      <Button
        label="Cancel"
        onClick={()=> setDeleteConfirmDialog(false)}
        className="btn-secondary w-8rem"
      />
      <Button label="Ok" onClick={onCheckIsAll} className="btn-primary w-8rem"/>
    </div>
  );
    
    return (
      <>
      <VivToast ref={toast_Ref} />
        <ConfirmationPopup
        ref={confirmationPopupRef}
        closable={true}
        title="Confirm"
        message={is_all ? "Are you sure you want to delete the reservation slots?" : "Are you sure you want to delete this reservation slot?"}
        onConfirm={onConfirm}
          onCancel={() => confirmationPopupRef.current.hidePopUp()}
          onHide={() => confirmationPopupRef.current.hidePopUp() }
      />
        {/* <SuccessPopup
      ref={successPopupRef}
        title=""
        message={""}
        messageTitle={"Reservation slot deleted successfully"}
        onHide={() => successPopupRef.current?.hidePopUp() } /> */}
    <Dialog
      header={deleteData.event_type_name}
      visible={deleteConfirmDialog}
      draggable={false}
      className="success_popup edit_is_all_dialog"
      style={{ width: "33vw" }}
      onHide={()=> setDeleteConfirmDialog(false)
      }
      footer={footerContentEditDialog}
      closable={true}
    >
        <div className="flex justify-content-start align-items-center gap-2">
          <RadioButton
          inputId="is_all"
            name="is_all"
            value={false}
          onChange={(value)=> setis_all(value.value)}
          checked={is_all === false}
        />
        <label htmlFor="never" className="ml-2 text-base">This event</label>
          <RadioButton
          inputId="is_all"
            name="is_all"
            value={true}
            className="ml-3"
          onChange={(value)=> setis_all(value.value)}
          checked={is_all === true}
        />
        <label htmlFor="never" className="ml-2 text-base">This & Following Events</label>
      </div>
    </Dialog>
            <AddEvent visible={showAddEvent} onChange={() => setShowAddEvent(false)} data={editData} type={type} />
            <Dialog
                header={headerContent}
                className="viv_dialog"
                headerStyle={{ textAlign: "left", color: "#212121", fontWeight: "500" }}
                visible={visible}
                style={{ maxWidth: "700px", width: "100%" }}
                position="center"
                draggable={false}
                resizable={false}
                onShow={onLoad}
                onHide={onChange}
            >
                <div className="grid event-list mt-2">

                    {eventsInfo.map((event) => (
                        
                        
                    <div key={event.id} className="col-12">
                        <Panel headerTemplate={(options) => headerTemplate(options, event)} toggleable className="event-panel" collapseIcon='ic-chev-up' expandIcon='ic-chev-down' collapsed={true}>
                           <div className="grid w-full gap-2">

                            <div className=" col-12  md:col-2 gap-1 event-detail-col">
                                <i className="ic-clock"></i>
                                
                                    <p className="">Time <br /> <span> {event.start_time.split(":")[0]} - {event.end_time.split(":")[0] } {event.end_time.split(" ")[1] } </span> </p>
                                    
                                
                            </div>

                            <div className="col-12 md:col-3 gap-1 event-detail-col">
                                <i className="ic-location"></i>
                                
                                    <p className="">Location <br /> <span> {event.location_name} </span> </p>
                                    
                                
                            </div>
                            
        
                            
                                    {event?.courts != null && (<div className="col-5 gap-1 event-detail-col">
                                        <i className="ic-court"></i>
                                
                                        <p className="">Assigned Courts <br /> <span>  {event.courts && event.courts.map((court, index) => (
                                            <span key={index}>{court.court_number}{index !== event.courts.length - 1 ? ', ' : ''}</span>
                                        ))} </span> </p>
                                    
                                
                                    </div>)}
                                    
                                    {event?.slots != null && (<div className="col-5 gap-1 event-detail-col">
                                        <i className="ic-court"></i>
                                
                                        <p className="">Assigned Courts <br /> <span>  {event.slots && event.slots.map((court, index) => (
                                            <span key={index}>{court.court_number}{index !== event.slots.length - 1 ? ', ' : ''}</span>
                                        ))} </span> </p>
                                    
                                
                                    </div>)}

                          
                            <div className="col flex justify-content-end gap-2  flex event-detail-edit">
                              <i className="ic-edit cursor-pointer" onClick={() => onEditEvent(event)}></i>
                              <i className="ic-delete cursor-pointer" onClick={() => onDeleteEvent(event)}></i>
                            </div>



                           </div>
                        </Panel>
                    </div>

))}

                    
                </div>
                
            </Dialog>
        </>
    )
}

export default EventListDialog;