import React, { useRef } from "react";
import { Dialog } from "primereact/dialog";
import EventListDialog from "../events-list-dialog";
import "./style.scss";
import { useState } from "react";
import EventViewDialog from "../event-view-dialog";
import { Tooltip } from 'primereact/tooltip';
import WaitListConfirmation from "../../../../shared/SuccessPopup";
import SlotListDialog from "../slot-list-dialog";
import PrivateSlotReserveDialog from "../private-slot-reserve-dialog";
import SlotReserveDialog from "../slot-reserve-dialog";
import { useDispatch } from "react-redux";
import { hideLoader, setEventSuccess, showLoader } from "../../../../redux/mainSlice";
import VivToast from "../../../../shared/VivitechToast";
import httpClient from "../../../../_util/api";
import ReservationCancelDialog from "../reservation-cancel-dialog";

const MonthEventViewDialog = ({visible,onHide,data , path}) => {

    const [eventPopupData, setEventPopupData] = useState([])
    const [eventListShow, setEventListShow] = useState(false)
    const [singleViewPopupData, setSingleViewPopUpData] = useState([])
    const dispatch = useDispatch();
    const [eventViewShow, setEventViewShow] = useState(false)
    const [slotReserveShow, setSlotReserveShow] = useState(false)
    const [slotListShow, setSlotListShow] = useState(false)
    const [privateSlotReserveShow, setPrivateSlotReserveShow] = useState(false)
    const WaitListConfirmationRef = useRef(null);
    const [cancelSlotReserveShow, setCancelSlotReserveShow] = useState(false)
    const toast_Ref = useRef(null);
    const handleShowMoreEvents = (data, event) => {
        event.stopPropagation()
        setEventPopupData(data)

        if (path == "location-schedule" || path == "my-reservation") {
            setSlotListShow(true)
        } else {
            setEventListShow(true)
        }
        // onHide()
    }
    
   
    
    const headerContent = ()=>(
        <div className="w-full month-event-view-dialog-header flex justify-content-between ">
            <h1>Event Type</h1>
            <i onClick={()=>{onHide()}} className="ic-close" />
        </div>
    )
    
    const onConfirmWaitList = async () => {
        dispatch(showLoader())

        try {
            const resp = await httpClient.post("/wait-list", {
                event_id: eventPopupData.event_id,
                occurrence_date: eventPopupData.occurrence_date,
                start_time: eventPopupData.start_time,
                end_time: eventPopupData.end_time
            });

            if (resp.status == 200) {
                toast_Ref.current?.showMessage(
                    "success",
                    "Waitlist Joined",
                    "",
                    "ic-square-check"
                )
                dispatch(setEventSuccess(`id-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`))
                setTimeout(() => {
                    WaitListConfirmationRef.current.hidePopUp()
                }, 500)
            }

        } catch (error) {
            toast_Ref.current?.showMessage(
                "error",
                error?.response?.data?.message || "An error occurred",
                "",
                "ic-error-notify"
            );
        }
        dispatch(hideLoader())
    }
    
      const handleEventShow = (data, event) => {
          event.stopPropagation()
          setEventPopupData(data)
          if (path == 'location-schedule' || path == "my-reservation") {

              if (data && data.event_type_separate == 'scheduled_play') {
                  if (data?.fully_booked) {

                      WaitListConfirmationRef.current.showPopUp()
                  } else {
                      if (path == 'location-schedule') {
                          setSlotReserveShow(true)
                      } else {
                          setCancelSlotReserveShow(true)
                      }
                  }
              } else {
                  setPrivateSlotReserveShow(true)
              }
          } else {
              setEventViewShow(true)
          }
        //  onHide()
    }
    
    
    return ( 
        <>
            <VivToast ref={toast_Ref} />
            <EventListDialog visible={eventListShow} onChange={() => setEventListShow(false)} data={eventPopupData} onDialogClose={onHide} />
            <EventViewDialog visible={eventViewShow} onChange={() => setEventViewShow(false)} data={eventPopupData} onDialogClose={onHide}/>
            <SlotReserveDialog visible={slotReserveShow} onChange={() => setSlotReserveShow(false)} data={eventPopupData} onDialogClose={onHide} />
            <PrivateSlotReserveDialog visible={privateSlotReserveShow} onChange={() => setPrivateSlotReserveShow(false)} data={eventPopupData} onDialogClose={onHide} />
            <ReservationCancelDialog visible={cancelSlotReserveShow} onChange={() => setCancelSlotReserveShow(false)} data={eventPopupData} />
            <SlotListDialog visible={slotListShow} onChange={() => setSlotListShow(false)} data={eventPopupData} onDialogClose={onHide}/>
            <WaitListConfirmation
                ref={WaitListConfirmationRef}
                closable={true}
                title=""
                messageTitle={"All Courts are booked. We have a waiting list of 12 member. Do you want to join waitlist?"}
                onConfirm={onConfirmWaitList}
                onCancel={() => WaitListConfirmationRef.current.hidePopUp()}
                onHide={() => WaitListConfirmationRef.current.hidePopUp()} />   
        <Dialog
        className="month-event-view-dialog pr-0 pl-3 py-2"
        visible={visible} 
        onHide={()=>{onHide()}}  
        modal
        header={headerContent}
        footer={<div className="month-event-view-dialog-header "></div>} 
        draggable={false}
        closable={false}
        resizable={false}
        dismissableMask 
        >

            <main className="w-full pr-3  pt-2   flex flex-column gap-1 justify-content-center">

            {data
    .filter((e, ind, arr) =>
        arr.findIndex(ev => ev.start_time === e.start_time && ev.end_time === e.end_time) === ind
    )
    .map((e, ind) => {
        const sameTimeEvents = data.filter(event => event.start_time === e.start_time && event.end_time === e.end_time);
        return (
            <div className="w-full  flex justify-content-between month-event-description gap-2">
                <Tooltip target={`.event-name-${e.event_id}`} position="top" content={e.event_type_name} showDelay={100} hideDelay={100}/>
                    <p className={`w-full event-name-${e.event_id} ${sameTimeEvents.length <=1 && 'cursor-pointer'}`} onClick={(ev)=>{sameTimeEvents.length <=1 && handleEventShow(e,ev)}}>{e.start_time.replace(':00', '')} - {e.end_time.replace(':00', '')} <b> {e.event_type_name} </b></p>
                    
                {sameTimeEvents.length > 1 && <span onClick={(ev) => handleShowMoreEvents(sameTimeEvents, ev)} className="cursor-pointer" > <b>{sameTimeEvents.length - 1}+</b> </span>}
                </div>
                )})}



            </main>

        </Dialog>
        </>
     );
}
 
export default MonthEventViewDialog;