import React, { useState, useReducer, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import httpClient from "../../../../../_util/api";
import { RadioButton } from 'primereact/radiobutton';
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, setEventSuccess, showLoader } from "../../../../../redux/mainSlice";
import ConfirmationPopup from "../../../../../shared/ConfirmationPopup";
import "../style.scss"
import VivToast from "../../../../../shared/VivitechToast";
import { Tooltip } from "primereact/tooltip";
import { Checkbox } from "primereact/checkbox";
import { useNavigate } from "react-router-dom";

const initialState = {
    event_id: null,
    event_type_id: null,
    slot_id: [],
    location_id: null,
    reservation_price: null,
    use_my_token:true
};

export default function SlotReserveComponent({ visible, onChange, data, viewMore, onDialogClose }) {
    const [formData, setFormData] = useState(initialState);
    const toast_Ref = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const locationId = useSelector((state) => state.mainSlice.locationId);
    const isLegacyMember = useSelector((state) => state.mainSlice.isLegacyMember);
    const [courts, setCourts] = useState([])
    const [courtStates, setCourtStates] = useState(courts);
    const [initialCourtStates, setInitialCourtState] = useState(courts);
    const [edit, setEdit] = useState(false)
    const [editConfirmDialog, setEditConfirmDialog] = useState(false)
    const confirmationPopupRef = useRef(null);
    const tokenPurchasePopupRef = useRef(null);
    const [useToken, setUseToken] = useState(true);
    const [showError, setShowError] = useState("")

    const handleChange = (e) => {


        setFormData((prevFormData) => ({ ...prevFormData, [e.name]: e.value }));

    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if (edit) {
            if (is_popup) {
                setEditConfirmDialog(true)
            } else {
                confirmationPopupRef.current.showPopUp()
            }
        } else {

            try {
                dispatch(showLoader())
                const response = await httpClient.post("/make-slot-reservation", formData);

                if (response?.status === 200) {

                    toast_Ref.current?.showMessage(
                        "success",
                        "Slot Reserved Successfully",
                        "",
                        "ic-square-check"
                    )
                    dispatch(setEventSuccess(`id-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`))
                    setTimeout(() => {
                        onChange()
                        if (onDialogClose) {
                            onDialogClose()
                        }
                    }, 1000)
                }

            } catch (error) {
                //console.log(error);
                
                if (error?.response?.data?.errors?.modal) {
                    tokenPurchasePopupRef.current.showPopUp();
                } else {
                    toast_Ref.current?.showMessage(
                        "error",
                        error?.response?.data?.message || "An error occurred",
                        "",
                        "ic-error-notify"
                    );    
                }
                
            }

            dispatch(hideLoader())
        }
    };



    const onCancel = async (e) => {
        e.preventDefault()
        setCourts([])
        onChange();
    }

    const headerContent = () => (
        <div className="flex justify-content-between event-view-dialog-header">
            <h1>{data?.event_type_name}</h1>
        </div>
    )


    const footerContent = (<>
        <div className="flex gap-3 justify-content-end private-reserve-dialog-footer">
            {showError && (<p className="error-message-add-event"><i className="ic-exclamation-circle"></i> You cxan not edit the reservation slot <br /> &nbsp;&nbsp;&nbsp;&nbsp; as court 1 has been booked.</p>)}
            {!viewMore && <Button
                onClick={onCancel}
                className="w-full btn-secondary text-white"
                label="Close"
                disabled={false}
            />}
            <Button
                className="w-full btn-primary text-white"
                label={
                    isLegacyMember && !formData.use_my_token
                        ? "Reserve Free"
                        : "Reserve"
                }
                onClick={onSubmit}
                disabled={formData?.slot_id?.length != 1 }
            />
        </div>
    </>
    );

    useEffect(() => {
        onLoad()
    }, []);





    const onLoad = async () => {
        console.log(isLegacyMember);
        
        
        if (isLegacyMember) {
            console.log("its hit");

            setUseMyToken(false)
            handleChange({ name: "use_my_token", value: false })
        }
        
        setFormData({
            event_id: data?.event_id,
            event_type_id: data?.event_type_id,
            location_id: locationId,
            token_per_reservation: data?.token_per_reservation,
            slot_id: courts,
            use_my_token: isLegacyMember ? false : true,
            token_cost: data?.token_cost
        })
        setCourtStates(data?.courts)
        setInitialCourtState(data?.courts)
        CourtSelection()
    }



    const handleFormUpdate = async () => {
        dispatch(showLoader())
        try {
            const response = await httpClient.put("/update-calender-event", formData);

        } catch (error) {
            toast_Ref.current?.showMessage(
                "error",
                error?.response?.data?.message || "An error occurred",
                "",
                "i-notify"
            );
        }
        dispatch(hideLoader())
    }


    const onConfirm = async () => {
        handleFormUpdate()

    };
    
    
    const onConfirmTokenPurchase = async () => {
        navigate("/wallet/purchase")
    }

    const onHide = () => {
        confirmationPopupRef.current.hidePopUp();
        setEditConfirmDialog(false)
    }


    const footerContentEditDialog = (
        <div className="mt-3 flex justify-content-end align-items-center gap-3">
            <Button
                label="Cancel"
                onClick={() => setEditConfirmDialog(false)}
                className="btn-secondary w-8rem"
            />
            <Button label="Ok" onClick={() => confirmationPopupRef.current.showPopUp()} className="btn-primary w-8rem" />
        </div>
    );


    const handleIconClick = (index) => {
        setCourtStates((prevStates) => {
            return prevStates.map((court, i) => {
                if (i === index) {
                    if (court.clicked) {

                        setCourts([]);
                        handleChange({ name: "slot_id", value: [] });

                        return { ...court, slots_reserved: Math.max(court.slots_reserved - 1, 0), clicked: false };
                    } else {
                        if (court.slots_reserved < 4) {
                            setCourts([court.id]);
                            handleChange({ name: "slot_id", value: [court.id] });

                            return { ...court, slots_reserved: court.slots_reserved + 1, clicked: true };
                        }
                    }
                } else {
                    const originalCount = initialCourtStates[i].slots_reserved;

                    return { ...court, clicked: false, slots_reserved: originalCount };
                }

                return court;
            });
        });
    };


    const CourtSelection = () => {
        return (
            <>
                {courtStates?.map((court, index) => (
                    <div className="col-12 md:col-4 flex flex-column" key={index}>
                        <p className="mb-3">{court.court_number}</p>
                        <div className={`flex justify-content-start align-items-center gap-3 ${court?.clicked && 'court-select'}`}>
                            <i
                                className="ic-reserve-court cursor-pointer"
                                onClick={() => court.slots_reserved != 4 && handleIconClick(index)}
                            ></i>
                            <div className="flex justify-content-start align-items-center gap-2">
                                {Array.from({ length: 4 }, (_, idx) => (
                                    <div
                                        key={idx}
                                        className={`outer-circle ${court.slots_reserved >= idx + 1 ? 'selected' : ''} ${(court.clicked ? idx < court.slots_reserved - 1 : idx < court.slots_reserved) && 'disabled'}`}
                                    >
                                        <div className="inner-circle"></div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </>
        );
    };
    
    
    function formatDateString(dateString) {
        const [dayOfWeek, monthAndDay] = dateString.split(', ');

        const shortDayOfWeek = dayOfWeek.slice(0, 3);
        const [month, day] = monthAndDay.split(' ');
        const shortMonth = month.slice(0, 3);

        return `${shortDayOfWeek}, ${shortMonth} ${day}`;
    }


    function formatTimeRange(startTime, endTime) {
        const formatTime = (time) => {
            const [hour, minutePart] = time.split(':');
            const [minute, period] = minutePart.split(' ');

            let hourNum = parseInt(hour, 10);

            return `${hourNum} ${period}`;
        };
        
        const formattedStartTime = formatTime(startTime);
        const formattedEndTime = formatTime(endTime);

        return `${formattedStartTime} - ${formattedEndTime}`;
    }


    const setUseMyToken = () => {
        setUseToken(!useToken)
        handleChange({ name: "use_my_token", value: !useToken })
    }




    return (
        <>
            <ConfirmationPopup
                ref={confirmationPopupRef}
                closable={true}
                title="Confirm"
                message={formData.is_all ? "Are you sure you want to update the reservation slots?" : "Are you sure you want to update this reservation slot?"}
                onConfirm={onConfirm}
                onCancel={onHide}
                onHide={onHide}
            />
            <ConfirmationPopup
                ref={tokenPurchasePopupRef}
                closable={true}
                title="Confirm"
                message={"You have issuficient tokens ? Would you like to purchase tokens ?"}
                onConfirm={onConfirmTokenPurchase}
                onCancel={() => tokenPurchasePopupRef.current.hidePopUp()}
                onHide={() => tokenPurchasePopupRef.current.hidePopUp()}
            />
            <VivToast ref={toast_Ref} />
            <Dialog
                header={"eventName"}
                visible={editConfirmDialog}
                draggable={false}
                className="success_popup edit_is_all_dialog"
                style={{ width: "33vw" }}
                onHide={() => setEditConfirmDialog(false)
                }
                footer={footerContentEditDialog}
                closable={true}
            >
                <div className="flex justify-content-start align-items-center gap-2">
                    <RadioButton
                        inputId="is_all"
                        name="is_all"
                        value={false}
                        onChange={(value) => handleChange({ name: "is_all", value: value.value })}
                        checked={formData.is_all === false}
                    />
                    <label htmlFor="never" className="ml-2 text-base">This event</label>
                    <RadioButton
                        inputId="is_all"
                        name="is_all"
                        value={true}
                        className="ml-3"
                        onChange={(value) => handleChange({ name: "is_all", value: value.value })}
                        checked={formData.is_all === true}
                    />
                    <label htmlFor="never" className="ml-2 text-base">This & Following Events</label>
                </div>
            </Dialog>
                <VivToast ref={toast_Ref} />
                <form className="max-w-full mx-auto viv-slot-reserve-dialog" onSubmit={onSubmit}>
                    <div className="grid cs-border-btm mb-4">
                        <div className={`event-view-schedule gap-2 col-12 ${isLegacyMember ? 'md:col-6' : 'md:col-4'}`}>
                            <i className="ic-reservation"></i>
                            <div className="flex flex-column">
                                <p className="reserve-headr">Reservation Price</p>
                            <span>{data?.token_per_reservation} Tokens Per Slot</span>
                            </div>
                        </div>
                    <div className={`event-view-schedule gap-2 col-12 ${isLegacyMember ? 'md:col-6' : 'md:col-4'}`}>
                            <i className="ic-clock"></i>
                            <div className="flex flex-column">
                                <p className="reserve-headr">Time</p>
                            <span>
                                {formatDateString(data?.occurrence_date_formated)}
                                {!isLegacyMember ? <br /> : <>&nbsp;&nbsp;&nbsp;&nbsp;</>}
                                {formatTimeRange(data?.start_time, data?.end_time)}
                            </span>
                            </div>
                        </div>
                    <div className={`event-view-schedule gap-2 col-12 ${isLegacyMember ? 'md:col-6' : 'md:col-4'}`}>
                            <i className="ic-location"></i>
                            <div className="flex flex-column">
                                <p className="reserve-headr">Location</p>
                                <span>{data.location_name}</span>
                            </div>
                        </div>
                    {isLegacyMember && <div className="event-view-schedule gap-2 col-12 md:col-6">
                        <i className="ic-orangecoins"></i>
                        <div className="flex flex-column">
                            <p className="reserve-headr flex align-items-center">Use My Tokens <i className="ic-mark ml-3 use-my-tokens"></i></p>
                            <Tooltip target={`.use-my-tokens`} className="slot-reserve-tooltip" content={"On active you want to reserve with tokens"} position="top" showDelay={100} hideDelay={100} />
                            <div className="grid reserve-available-court">
                                <div className="col-5 flex gap-1 mt-2">
                                    <Checkbox
                                        checked={formData?.use_my_token}
                                        onChange={setUseMyToken}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <div className="flex justify-content-start align-items-center gap-2 mb-3">
                                <i className="ic-court"></i>
                                <p className="reserve-headr">Available Courts</p>
                            </div>
                            <div className="grid reserve-available-court">
                                {CourtSelection()}
                            </div>
                        </div>
                </div>
                {footerContent}
                </form>
        </>
    );
}
