import React, { useState, useRef, useEffect } from "react";
import { Route, useNavigate ,useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import Notification from "./Notification";
import { useDispatch, useSelector } from "react-redux";
import { showLoader, hideLoader, mainSlice, setLocation_id } from "../redux/mainSlice";
import { showMobileBar } from "../redux/sidebarSlice";
import { OverlayPanel } from 'primereact/overlaypanel';
import { Divider } from 'primereact/divider';
import ConfirmationPopup from "../shared/ConfirmationPopup";
import httpClient from "../_util/api";
import FromSelect from "../shared/Form-Select";
import FormInput from "../shared/Form-Input";

const AppTopBar = () => {
  const toast_Ref = useRef(null);
  const menuLeft = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.mainSlice.userdetail);
  const location_id = useSelector((state) => state.mainSlice.locationId);
  const userRole = useSelector((state) => state.mainSlice.role);
  const confirmationPopupRef = useRef(null);
  const [locationId, setLocationId] = useState()
  const [locationDetails, setLocationDetails] = useState()
  const userImage = useSelector((state) => state.mainSlice.userImage);
  const [pageTitle, setPageTitle] = useState("");

  const op = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredLocations, setFilteredLocations] = useState([]);
  
  let location = useLocation();

  const handleLogout = () => {
    confirmationPopupRef.current?.showPopUp();
  };

  const setLocation = async (value) => {
    setLocationId(value)
    dispatch(setLocation_id(value))
  }

  const fetchLocations = async () => {
    try {
      const { data } = await httpClient.get("/lookup-locations");
      if (data.status == 200) {
        setLocationDetails(data.data)
        setFilteredLocations(locationDetails);
        
        if (location_id == null) {
          // setLocationId(data.data[0].id)
          dispatch(setLocation_id(data.data[0].id))
        }
        
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchLocations()
  }, []);

  useEffect(()=>{
    const pathSegments = location?.pathname?.split('/').filter(Boolean);
    let lastSegment;
    if (pathSegments.length > 1) {
      lastSegment = pathSegments[1]; 
    if(pathSegments?.length==3 && pathSegments?.includes('detail')){
      lastSegment=pathSegments[0];
    }
    } else {
      lastSegment = pathSegments[pathSegments?.length - 1]; 
    }
    const formattedTitle = lastSegment?.replace('-', ' '); 
    setPageTitle(formattedTitle);
  },[location.pathname])



  const accept = async () => {
    dispatch(showLoader());
    try {
      const resp = await httpClient.post("logout");
      if (resp.status === 200) {
        dispatch(hideLoader());
        dispatch(mainSlice.actions.clearUserDetail());
        dispatch(mainSlice.actions.clearUserRole());
        dispatch(mainSlice.actions.clearUserMenus());
        dispatch(mainSlice.actions.clearLocation_id());
        localStorage.removeItem('user-token');
        navigate("/login");
      }
    } catch (error) {
      toast_Ref.current?.showMessage(
        "error",
        error?.response?.data?.message || "An error occurred",
        "",
        "ic-error-notify"
      );
      dispatch(hideLoader());
    }

  };

  useEffect(() => {
    const filtered = locationDetails?.filter(location =>
      location.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredLocations(filtered);
  }, [searchTerm, locationDetails]);

  const handleLocationSelect = (locationId) => {
    setLocation(locationId);
    //setFilteredLocations(locationDetails);
    setSearchTerm('');
    op.current.hide(); 
  };
  
  const handleLocationSelectionChange = (value)=>{
    setSearchTerm(value);
  }

  return (
    <div className="viv_header align-content-center align-items-center flex justify-content-between">
      <Button className="hidden-lg hidden-md menu-toggle" icon={'pi i-menu'} onClick={() => { dispatch(showMobileBar()) }} />
      <h1 className=" hidden md:block topbar-title ">TP Resort</h1>
      <h1 className=" block md:hidden topbar-title capitalize">{pageTitle}</h1>

      

      <ConfirmationPopup
        ref={confirmationPopupRef}
        closable={true}
        title="Logout"
        message="Are you sure you want to logout?"
        onConfirm={() => {
          accept(); // Handle form submission on "Yes"
          confirmationPopupRef.current?.hidePopUp(); // Hide popup
        }}
        onCancel={() => confirmationPopupRef.current?.hidePopUp()}
        onHide={() => confirmationPopupRef.current?.hidePopUp()}// Hide popup on "No"
      />
      
      
     
      <div className="user_info align-items-center flex justify-content-between">
      
      <div className="align-items-center flex justify-content-between flex-row-reverse md:flex-row">
      <div>
      <img  onClick={(e) => op.current.toggle(e)} className="align-self-center flex hidden-sm hidden-lg hidden-md cursor-pointer" src="/images/pin-location.svg" alt="" />
      <OverlayPanel className="overlay-panel-custom" ref={op} onHide={() => setSearchTerm('')}>

      <div className='search flex  align-items-center justify-content-end pl-2 pr-2 mb-3'>
                <FormInput
                inputtype={"text"}
                  placeholder={"Search"}
                  value={searchTerm}
                  onchange={(e) => handleLocationSelectionChange(e)}
                />
                <i className="i-search mr-3 absolute" />
              </div>
          

        <div className="location-list">
          {filteredLocations?.map((location) => (
            <div
              key={location.id}
              className={`p-1 ${location_id && location.id === location_id ? 'active-location' : ''}`}
              onClick={() => handleLocationSelect(location.id)}
            >
              {location.name}
            </div>
          ))}
        </div>
      </OverlayPanel>
    </div>

    <div className="md:flex md:gap-1 hidden">

      <img className="align-self-center" src="/images/pin-location.svg" alt="" />
      <FromSelect
          filter={true}
          title={``}
          optionValue='id'
          placeholder="Select Location"
          onSelect={(value) => setLocation(value)}
          selectedOption={location_id}
          options={locationDetails}
          styleclass={"pinloc-dropdown-cont"}
          ispinloc = {true}
        />
        </div>
       
        {userRole == "Member" && <Notification />}
        </div>
        <img
          style={{ borderRadius: "50%" }}
          width={50}
          height={50}
          src={userInfo?.image_path ? userInfo?.image_path : "/images/profile-pic.png"}
          onClick={(event) => menuLeft.current.toggle(event)}
          onError={(e) => {
            e.target.src = "/images/profile-pic.png";
          }}
          className="cursor-pointer top-bar-img"
        />
        <div className="user_name flex flex-column">
          {
            userInfo?.roles?.includes('Admin') && <p id="user_role">Admin</p>
          }
          <p id="user">{userInfo?.username}</p>
        </div>
        <div className="align-items-center flex">
          {/* Custom Profile Menu */}
          <OverlayPanel ref={menuLeft} className="popup_userInfo">
            <div className="profile-menu">

              <div className="profile-section">
                <img
                  src={userInfo?.image_path ? userInfo?.image_path : '/images/profile-pic.png'}
                  alt="Profile"
                  className="profile-image"
                  onError={(e) => {
                    e.target.src = "/images/profile-pic.png";
                  }}
                />
                <div className="profile-details">
                  <div className="profile-name">{userInfo?.first_name} {userInfo?.last_name}</div>
                  <div className="profile-email">{userInfo?.email}</div>
                </div>
              </div>
              <Divider />

              <div className="menu-items">
                <div className="menu-item" onClick={handleLogout} >
                  <i className="pi pi-sign-out" style={{ marginRight: '8px' }}></i>
                  Log out
                </div>
              </div>
            </div>
          </OverlayPanel>
        </div>
      </div>
    </div>
  );
};

export default AppTopBar;
