import React, { useState, useEffect, useCallback, useRef } from "react";
import { PanelMenu } from "primereact/panelmenu";
import { useLocation } from "react-router-dom";
import MenuLink from "../router/menuLink";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from 'primereact/tooltip';
import ConfirmationPopup from "../shared/ConfirmationPopup";
import httpClient from "../_util/api";
import { useNavigate } from "react-router-dom";
import { showLoader, hideLoader, mainSlice } from "../redux/mainSlice";

export const AppMenu = () => {
  const toast_Ref = useRef(null);
  const confirmationPopupRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [menu, setMenu] = useState([]);
  const userMenus = useSelector((state) => state.mainSlice.userMenus);
  const sidebarType = useSelector((state) => state.sidebarSlice.value);

  let newMenu = [];

  const renderMenus = useCallback(() => {
    newMenu = [];
    if (sidebarType === "full-bar") {
      userMenus.forEach(m => {
        if (m?.submenu && m?.submenu.length) {
          let actMenu = [{
            label: m.title,
            icon: "ic-" + m.icon,
            className: location.pathname.includes(m.title.toLowerCase()) ? "activepanel" : "",
            expanded: location.pathname.includes(m.title.toLowerCase()), // Ensures the menu stays open based on location
            items: [],
          }];

          m.submenu.forEach((f, ind) => {
            actMenu[0].items.push({
              template: (
                <MenuLink
                  key={ind}
                  to={m.title.toLowerCase() + f.url}
                  classes="p-menuitem-link"
                  text={f.title}
                  onClick={(e) => e.stopPropagation()} // Prevent event from closing the main menu
                />
              ),
            });
          });

          newMenu.push(actMenu[0]);
        } else {
            newMenu.push({
            label: m.title,
            className: `single-menu ${location.pathname.includes(m.title) ? "activepanel" : ""}`,
            icon: "ic-franchise",
            template: (
              <MenuLink
              to={m.url}
              classes="p-panelmenu-header-link"
              icon={"ic-" + m.icon}
              text={m.title}
              onClick={(e) => e.stopPropagation()} // Prevent event from closing the main menu
              />
            ),
          });
        }
      });
    } else if (sidebarType === "half-bar") {
      userMenus.forEach(m => {
        if (m?.submenu && m?.submenu.length) {
          let actMenu = [{
            label: m.title,
            icon: "ic-" + m.icon,
            template: (
              <MenuLink
                to={m.title.toLowerCase() + m.submenu[0].url}
                classes="p-panelmenu-header-link"
                icon={"ic-" + m.icon}
                text={m.title}
                title={m.title}
                onClick={(e) => e.stopPropagation()} // Prevent event from closing the main menu
              />
            )
          }];

          newMenu.push(actMenu[0]);
        } else {

          newMenu.push({
            label: m.title,
            className: location.pathname.includes(m.title) ? "activepanel" : "",
            icon: "ic-franchise",
            template: (
              <MenuLink
                to={m.url}
                classes="p-panelmenu-header-link"
                icon={"ic-" + m.icon}
                text={m.title}
                title={m.title}
                onClick={(e) => e.stopPropagation()} // Prevent event from closing the main menu
              />
            ),
          });
        }
      });
    }

    setMenu([...newMenu]);  // Update state with the new menu structure
  }, [userMenus, sidebarType]);

  const handleLogout = () => {
    confirmationPopupRef.current?.showPopUp();
  }

  const accept = async () => {
    dispatch(showLoader());
    try {
      const resp = await httpClient.post("logout");
      if (resp.status === 200) {
        dispatch(hideLoader());
        dispatch(mainSlice.actions.clearUserDetail());
        dispatch(mainSlice.actions.clearUserRole());
        dispatch(mainSlice.actions.clearUserMenus());
        dispatch(mainSlice.actions.clearLocation_id());
        localStorage.removeItem('user-token');
        navigate("/login");
      }
    } catch (error) {
      toast_Ref.current?.showMessage(
        "error",
        error?.response?.data?.message || "An error occurred",
        "",
        "ic-error-notify"
      );
      dispatch(hideLoader());
    }

  };

  useEffect(() => {
    renderMenus();
  }, [userMenus, sidebarType]);

  useEffect(() => {
    let menus = [...menu];
    
    if(menus?.length){

      menus.forEach(item => {

          if(location.pathname.includes(item.label?.toLowerCase())){
            item.expanded = false;
            item.className = "activepanel";
          } else {
            if(location.pathname.includes(item.label?.toLowerCase().replace(" ", "-"))){
              item.expanded = true;
              item.className = "single-menu activepanel";
            } else {
              item.expanded = false;
              item.className = item.items?.length ? "" : "single-menu";
            }
        }
        // if(item.items?.length && !location.pathname.includes(item.label?.toLowerCase())){
        //   item.expanded = true;
        //   item.className = ""
        // }
      });
      console.log(menus)
      setMenu([...menus]);
    }
    
  }, [location])

  return (
    <>
      <ConfirmationPopup
        ref={confirmationPopupRef}
        closable={true}
        title="Logout"
        message="Are you sure you want to logout?"
        onConfirm={() => {
          accept(); // Handle form submission on "Yes"
          confirmationPopupRef.current?.hidePopUp(); // Hide popup
        }}
        onCancel={() => confirmationPopupRef.current?.hidePopUp()}
        onHide={() => confirmationPopupRef.current?.hidePopUp()}// Hide popup on "No"
      />

      <PanelMenu model={menu} className="w-full viv_sideMenus" />
      
      <div className="viv_sidemenu_profile_tab">
        <Link to="/profile"  >
          <p className={`flex justify-content-start align-items-center ${location.pathname.includes("profile") && "profile-tab-active"}`}>
            <i className="pi pi-user sidemenu-profile-tab  mr-3"></i>
            <span >
              Profile
              {sidebarType == "half-bar" && <Tooltip className={"custom-tooltip-menu"} target={`.sidemenu-profile-tab`} content={"Profile"} position="right" showDelay={100} hideDelay={100} />}
            </span>
          </p>

        </Link>
        <div className=" menu-items mb-2 flex justify-content-center md:hidden ">
          <div className="flex align-items-center menu-item" onClick={handleLogout} >
            <i className="pi pi-sign-out" style={{ marginRight: '8px' }}></i>
            <span className="logout-span">Log out</span>
          </div>
        </div>
      </div>

    </>
  );
};
