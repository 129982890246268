import React, { useState, useReducer, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import PrivateSlotReserveComponent from "./components";


export default function PrivateSlotReserveDialog({ visible, onChange, data, onDialogClose }) {
    const [edit, setEdit] = useState(false)

    useEffect(() => {

    }, []);
    
    const headerContent = () => (
        <div className="flex justify-content-between event-view-dialog-header">
            <h1>{data?.event_type_name}</h1>
            {edit && <div className="flex gap-2">
                <i className="ic-edit header-icon ml-2" />
                <i className="ic-delete header-icon ml-2" />
            </div>}
        </div>
    )
    
    const onSuccess = () => {
        if (onDialogClose) {
            onDialogClose();
        }
    }
    
    
    const handleEdit = (data) => {
        setEdit(true);
    };


    return (
        <>
            <Dialog
                header={headerContent}
                className="sign_up_dialog"
                headerStyle={{ textAlign: "left", color: "#212121", fontWeight: "500" }}
                visible={visible}
                style={{ maxWidth: "540px", width: "100%" }}
                position="center"
                draggable={false}
                resizable={false}
                closable={false}
                onHide={onChange}
            >
                <PrivateSlotReserveComponent data={data} onEdit={handleEdit} onChange={onChange} onDialogClose={onSuccess} />
            </Dialog>
        </>
    );
}
